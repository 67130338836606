import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FileUploadItem = ({
  className,
  progress,
  fileIndex,
  fileName,
  fileSize,
  handleRemove
}) => {
  const getFileSizeInKB = size => {
    const fileSizeInKb = fileSize / 1024;
    return fileSizeInKb.toFixed(2);
  }
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.progressBar)} style={{width: `${progress}%`}}/>
      <div className={styles.fileInfo}>
        <p className={styles.fileName}>{fileName}</p>
        <p className={styles.fileSize}>{getFileSizeInKB(fileSize)}kb</p>
      </div>
      <IconButton sx={{color: "#2666E6", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} onClick={() => handleRemove(fileIndex)}>
        <CloseIcon sx={{width: "22px"}}/>
      </IconButton>
    </div>
  )
};

export default FileUploadItem;
