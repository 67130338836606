import React from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Home from "../pages/Home";
import Tenant from "../pages/Tenant";
import CreateTenant from "../pages/CreateTenant";
import AbstractPage from '../pages/Abstract';

// import PropertyView from "../components/PropertyView";
import LeaseView from "../components/LeaseView";
import UpdateTenant from "../components/UpdateTenant";
import {leaseViewData} from "../data/mock-data";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route path="/:path(|propertyview)">
          <PropertyView {...propertyViewData} />
        </Route> */}
        <Route exact path="/leaseview">
          <LeaseView {...leaseViewData} />
        </Route>
        <Route exact path="/createtenant/:propertyId" component={CreateTenant} />
        <Route exact path="/tenantview/:propertyId" component={Tenant} />
        <Route exact path="/abstract/:tenantId" component={AbstractPage} />
        <Route exact path="/updatetenant" component={UpdateTenant} />
        <Redirect to={'/'} />
      </Switch>
  </Router>
  );
}

export default Routes;
