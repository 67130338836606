import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";
import MuiLink from '@mui/material/Link';
import {Authenticator} from "@aws-amplify/ui-react";

function TopNav() {
  return (
    <Box sx={{boxShadow: "0px 4px 20px rgba(126, 126, 126, 0.25);"}}>
      <Container component="header" maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" height="60px" >
          <MuiLink variant="button" underline="none" href="/" sx={{color: "#2666E6", fontWeight: "bold"}}>
            WHITEMARSH PRO
          </MuiLink>
          <Authenticator>
            {({ signOut, user }) => (
                <><Button sx={{fontSize: "16px", color: "#232839", textTransform: "none"}} onClick={signOut}>Logout</Button></>
            )}
          </Authenticator>
          
        </Stack>
      </Container>
    </Box>
  );
}

export default TopNav;
