import React, {useState} from "react";
import { Link } from "react-router-dom";
import PrimaryButton3 from "../PrimaryButton3";
import "./UpdateTenant.css";
import ToggleButton from '@mui/material/ToggleButton';
import TenantCard from "../TenantCard";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Amplify, { Auth,Storage, S3Image} from "aws-amplify";
import awsconfig from "../../aws-exports";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createUnit, createAmendment, createLease, createProperty, updateUnit, updateProperty} from "../../graphql/mutations";
import { onCreateProperty, onCreateUnit, onCreateLease, onCreateAmendment } from "../../graphql/subscriptions";
import { getProperty, listAmendments, listLeases } from "../../graphql/queries";
import { useLocation, useHistory } from "react-router-dom";
import * as ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { IconButton } from "@mui/material";
import AmendmentCards from '../AmendmentCards';
import LeaseCard from '../LeaseCard';
// import './styles.css';

function UpdateTenant(props) {
  const history = useHistory();
  const [value, setValue] = React.useState({});
  const [newProperty, setNewProperty] = React.useState("");
  const [newUnit, setNewUnit] = React.useState("");
  const [newLease, setNewLease] = React.useState("");
  const [newAmendment, setNewAmendment] = React.useState("");
  const [alignment, setAlignment] = React.useState('amendment');
  const [unitAddition, setUnitAddition] = React.useState('lease');
  const [x5ParagonDrive, setx5ParagonDrive] = React.useState("");
  const [date, seteDate] = React.useState("");
  const [className, setClassname] = React.useState("");
  const [group483Props, setGroup483Props] = React.useState("");
  const [unitLease, setUnitLease] = React.useState("");
  const [unitAmendments, setUnitAmendments] = React.useState("");
  const [unitName, setUnitName] = useState("");
  const [squareFeet, setSquareFeet] = useState("")

  const primaryButton222Data = {
    addANewProperty: "UPLOAD",
  };
  const [uploadType, setUploadType] = React.useState("lease");
  const location = useLocation();
  const {unit, property}= location.state;
  console.log("Updating Tenant. Unit coming in: ", newUnit);
  React.useEffect(() => {
    async function getLease(newUnit2) {
      console.log("NEW UNIT DATA: ", newUnit2)
      const result2 = await API.graphql(graphqlOperation(listLeases, {
            filter: {
                unitLeaseId: {
                    eq: newUnit2.id
                }
            }
        }));
      await setUnitLease(result2.data.listLeases)
      console.log("Unit Lease is: ", result2.data.listLeases)
    }
    // getLease();
    async function getAmendments(newUnit2) {
      const result2 = await API.graphql(graphqlOperation(listAmendments, {
            filter: {
                unitAmendmentsId: {
                    eq: newUnit2.id
                }
            }
        }));
      await setUnitAmendments(result2.data.listAmendments)
      console.log("Unit Amendments are: ", result2.data.listAmendments)
    }
    try{
      if(unit.unit != undefined){
        setNewUnit(unit.unit)
        setx5ParagonDrive(unit.unit.name)
        seteDate(unit.unit.updatedAt)
        setClassname(unit.unit.category)
        setGroup483Props({x5ParagonDrive, date, className})
        setSquareFeet(unit.unit.squareFeet)
        getLease(unit.unit)
        getAmendments(unit.unit)
        // getAmendments();
      }
      if(property.property != undefined){
        setNewProperty(property.property)
      }
    }
    catch(error){
      console.log("no unit coming in")
      setNewUnit(undefined)
      setNewProperty(undefined)
    }

  }, [unit, property]);

  // check if prop exists if not do below
  const primaryButton3Data = {
    addANewProperty: "BEGIN ABSTRACT",
    className: "primary-button-2",
  };

  const createTenantData = {
    rectangle150: "",
    rectangle162: "",
    creatingANewTenant: "Update Tenant",
    step2AddTheLeas: "Step 3: Add the lease file and any amendments",
    step1NameTenant: "Tenant Information",
    step3OrganizeTheFilesAsNeeded: "Step 3: Organize the files as needed",
    selectThePdfFilesToUpload: "Select the PDF files to upload",
    orDragAndDropThemHere: "Or drag and drop them here.",
    inputType: "text",
    inputPlaceholder: "Dunkin Donuts",
    orUploadThemFromAUrl: "Or, upload them from a URL",
    addTheUrl: "Add the URL",
    lease: "Lease",
    amendments: "Amendments",
    dragAndDropYourF: "Drag and drop your files from the left column to here. We recommend rearranging your amendments in chronological order.",
    primaryButton22Props: primaryButton222Data,
    primaryButtonProps: primaryButton3Data,
  };
  const {
    rectangle150,
    creatingANewTenant,
    step2AddTheLeas,
    step1NameTenant,
    step3OrganizeTheFilesAsNeeded,
    selectThePdfFilesToUpload,
    orDragAndDropThemHere,
    inputType,
    inputPlaceholder,
    orUploadThemFromAUrl,
    addTheUrl,
    lease,
    amendments,
    dragAndDropYourF,
    primaryButton4Props,
    primaryButtonProps,
  } = createTenantData;

  function createDeepLink(){
    fetch('https://mywebsite.com/endpoint/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: {
        "project_id": "4d4c1ec885452a996937f253",
        "file_name": "Profile.pdf",
        "file_type": "application/pdf",
        "source": "s3://object-pocs/kbcgconsulting/Leases 20220402/Leases 7/Gameday in Style/Leases/Profile.pdf",
        "create_deep_link": {
            "user_name": "Lucas",
            "user_email": "lucas@kbcgconsulting.com",
            "role": "annotator",
            "expire_in": 1,
            "return_url": "https://google.com"
                            }
            }
    })
    // body = {
      
    //   "project_id": "4d4c1ec885452a996937f253",
    //   "file_name": "Profile.pdf",
    //   "file_type": "application/pdf",
    //   "source": "s3://object-pocs/kbcgconsulting/Leases 20220402/Leases 7/Gameday in Style/Leases/Profile.pdf",
    //   "create_deep_link": {
    //       "user_name": "Lucas",
    //       "user_email": "lucas@kbcgconsulting.com",
    //       "role": "annotator",
    //       "expire_in": 1,
    //       "return_url": "https://google.com"
    //                       }
    //       }
  }
  // Toggle switch button
  function ColorToggleButton() {  
    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
    ) => {
      setAlignment(newAlignment);
      setUploadType(newAlignment);
      console.log("User chose upload type: ", newAlignment);
    };
  
    return (
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="lease">Lease</ToggleButton>
        <ToggleButton value="amendment">Amendment</ToggleButton>
      </ToggleButtonGroup>
    );
  }




  // Use this function if upload type is toggled to Lease
  async function handleUploads(files, property, unitName){
    console.log("property in handleLeaseUploads: ", property)
    const user = await Auth.currentAuthenticatedUser();
// Subscription for new Unit
    // async function newUnitListener(){
    // Auth.currentAuthenticatedUser().then(user => {
    //   API.graphql(
    //     graphqlOperation(onCreateUnit, { owner: user.username })
    //   ).subscribe(incomingUnit=> {
    //     const newUnit1 = incomingUnit.value.data.onCreateUnit;
    //     const input = {
    //       id: newProperty.id,
    //       units: { items: [newUnit1, ...newProperty.units.items] }
    //     }
    //     const result = API.graphql(graphqlOperation(updateProperty, { input }));
    //     console.log("result from sub: ", result)
    //     // setNewProperty(result.data.updateProperty);
    //   });
    // });
    // };
    // newUnitListener();
    

    // Subscription for adding link from amendments and leases to Units
    // Auth.currentAuthenticatedUser().then(user => {
    //   API.graphql(
    //     graphqlOperation(onCreateLease, { owner: user.username })
    //   ).subscribe(incomingLease=> {
    //     try{
    //       // update unit
    //       async function updateU(){
    //         console.log("unit in oncreateLease: ", newUnit)
    //         const leaseRecord = incomingLease.value.data.onCreateLease;
    //         const input = {
    //           id: newUnit.id,
    //           unitLeaseId: leaseRecord.id
    //         }
    //         const result = await API.graphql(graphqlOperation(updateUnit, { input }));
    //         console.log("lease subscriptions REsulet: ", result);
    //         await setNewUnit(result.data.updateUnit);
    //         console.log("Unit Updated: ", result.data.updateUnit);
    //       };
    //     updateU();
    //     }catch{
    //       console.log("ERrororor")
    //     }

    //   });
    // });
    // Auth.currentAuthenticatedUser().then(user => {
    //   API.graphql(
    //     graphqlOperation(onCreateAmendment, { owner: user.username })
    //   ).subscribe(incomingAmendment =>{
    //     try{
    //       // update unit
    //       async function updateU(){
    //         console.log("unit in oncreateAmendment: ", newUnit)
    //         const amendmentRecord = incomingAmendment.value.data.onCreateAmendment;
    //         const input = {
    //           id: newUnit.id,
    //           unitAmendmentsId: amendmentRecord.id
    //         }
    //         const result = await API.graphql(graphqlOperation(updateUnit, { input }));
    //         console.log("amendment subscription REsulet: ", result);
    //         await setNewUnit(result.data.updateUnit);
    //         console.log("Unit Updated: ", result.data.updateUnit);
    //       };
    //     updateU();
    //     }catch{
    //       console.log("ERrororor")
    //     }

    //   });
    // });
    for (let i = 0; i < files.length; i++) { 
      files.item(i)
      console.log("Trying to upload files: ", files[i])
      const fileForUpload = files[i];
      // const file = fileForUpload || value;
      const fileName = fileForUpload.name.split(".")[0];
      const extension = fileForUpload.name.split(".")[1];
      const { type: mimeType } = fileForUpload;
      // const key = `uploadedLeases/${uuid()}${property.name}.${extension}`;
      const leaseKey = `${newUnit.property.name}/${newUnit.name}/Lease_${fileName}.${extension}`;
      const amendmentKey = `${newUnit.property.name}/${newUnit.name}/Amendment_${fileName}.${extension}`;
      const leaseName = unitName + "_Lease";
      const amendmentName = unitName + "_Amendment"
      try {

          if(alignment == "lease"){
            await Storage.put(leaseKey, fileForUpload)
            .then (result => console.log(result))
            .catch(err => console.log(err));
            if(newUnit == undefined){
              async function addUnitAndLease(){
                // first add Unit 
                const input = {
                  squareFeet: squareFeet,
                  name: unitName,
                  createdAt: `${Date.now()}`,
                  propertyUnitsId: property.property.id,
                }
                const result = await API.graphql(graphqlOperation(createUnit, { input }));
                await setNewUnit(result.data.createUnit);
                console.log("Unit Created: ", result.data.createUnit)
                // Then add Lease)
                const input2 = {
                  id: leaseKey,
                  bucket: awsconfig.aws_user_files_s3_bucket,
                  deepLink: "",
                  s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + leaseKey,
                  name: leaseName,
                  createdAt: `${Date.now()}`,
                  unitLeaseId: result.data.createUnit.id,
                }
                console.log(input2)
                const result2 = await API.graphql(graphqlOperation(createLease, {input: input2 }));
                await setNewLease(result2.data.createLease)
                console.log("Lease Created: ", result2.data.createLease)
                window.location.reload(false);
              };
              addUnitAndLease()
            }
            else{
              async function updateUnitAndLease(){
                // first update Unit 
                const input = {
                  id: newUnit.id,
                  // add a button to get square feet
                  squareFeet: squareFeet,
                  // name: unitName,
                  createdAt: `${Date.now()}`,
                  // propertyUnitsId: newProperty.id,
                }
                const result = await API.graphql(graphqlOperation(updateUnit, { input }));
                await setNewUnit(result.data.updateUnit)
                console.log("Unit updated: ", result.data.updateUnit)
                // Then add Lease)
                const input2 = {
                  id: leaseKey,
                  bucket: awsconfig.aws_user_files_s3_bucket,
                  deepLink: "",
                  s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + leaseKey,
                  name: leaseName,
                  createdAt: `${Date.now()}`,
                  unitLeaseId: result.data.updateUnit.id,
                }
                const result2 = await API.graphql(graphqlOperation(createLease, {input: input2 }));
                await setNewLease(result2.data.createLease)
                console.log("Lease Created: ", result2.data.createLease)
                // Reload page here
                window.location.reload(false);
              };
              updateUnitAndLease()
            }
            
          }
          else{
            await Storage.put(amendmentKey, fileForUpload)
            .then (result => console.log(result))
            .catch(err => console.log(err));
            if(newUnit == undefined){
              async function addUnitAndAmendment(){
                // first add Unit 
                const input = {
                  squareFeet: squareFeet,
                  name: unitName,
                  createdAt: `${Date.now()}`,
                  propertyUnitsId: property.property.id,
                }
                const result = await API.graphql(graphqlOperation(createUnit, { input }));
                await setNewUnit(result.data.createUnit);
                console.log("Unit Created: ", result.data.createUnit)
                // Then add amendment)
                const input2 = {
                  id: amendmentKey,
                  bucket: awsconfig.aws_user_files_s3_bucket,
                  deepLink: "",
                  s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + amendmentKey,
                  name: amendmentName,
                  createdAt: `${Date.now()}`,
                  unitAmendmentsId: result.data.createUnit.id,
                }
                const result2 = await API.graphql(graphqlOperation(createAmendment, {input: input2 }));
                await setNewLease(result2.data.createAmendment)
                console.log("Amendment Created: ", result2.data.createAmendment)
                window.location.reload(false);
              };
              addUnitAndAmendment()
            }
            else{
              async function updateUnitAndAmendment(){
                // first add Unit 
                const input = {
                  id: newUnit.id,
                  squareFeet: squareFeet,
                  // name: unitName,
                  createdAt: `${Date.now()}`,
                  // propertyUnitsId: newProperty.id,
                }
                const result = await API.graphql(graphqlOperation(updateUnit, { input }));
                await setNewUnit(result.data.updateUnit)
                console.log("Unit Updated: ", result.data.updateUnit)
                // Then add Amendment)
                const input2 = {
                  id: amendmentKey,
                  bucket: awsconfig.aws_user_files_s3_bucket,
                  deepLink: "",
                  s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + amendmentKey,
                  name: amendmentName,
                  createdAt: `${Date.now()}`,
                  unitAmendmentsId: result.data.updateUnit.id,
                }
                const result2 = await API.graphql(graphqlOperation(createAmendment, {input: input2 }));
                await setNewAmendment(result2.data.createAmendment)
                console.log("Amendment Created: ", result2.data.createAmendment)
                window.location.reload(false);
              };
              updateUnitAndAmendment()
            }
          }
      
      } catch (err) {
          console.log("error: ", err);
      }
    }
  }

const handleNameChange = event => {
    setUnitName(event.target.value);
  };
const handleSquareFeetChange = event => {
  setSquareFeet(event.target.value)
} 
// First get the units of the property
function setProps ({unitName1, updatedAt1, category1}) {
  setGroup483Props({unitName1, updatedAt1, category1});
  console.log("groupprops: ", group483Props);
};

  // drag drop file component
function DragDropFile() {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = React.useRef(null);
  
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploads(e.dataTransfer.files, property, unitName)
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files)
      handleUploads(e.target.files, property, unitName)
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
        </div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  );
};
  return (
    <div className="container-center-horizontal">
      <div className="createtenant screen">
        <div className="overlap-group3-2">
          <div className="rectangle-159-1"></div>
          <div className="rectangle-165 border-1px-ebb"></div>
          <img className="rectangle-162-1" src='/img/rectangle-162@1x.svg' />
          {/* <Link to={{ pathname: "/tenantview", state: { propName:{propName}, propStreet:{propStreet}, propCity:{propCity}, propZipcode:{propZipcode}, propState:{propState}, propSqft:{propSqft}, property:property.property, propCategory:{propCategory}}}}> */}
          <IconButton onClick={() => history.goBack()}>
            <div className="group-349-1">
              <img className="vector-2" src="/img/vector-1@2x.svg" />
            </div>
          </IconButton>
          
          <div className="creating-a-new-tenant lato-bold-tory-blue-20px">{creatingANewTenant}<DragDropFile></DragDropFile></div>
          <div className="create-property-steps name lato-bold-mirage-18px">{step1NameTenant}</div>
          <div className="group-491">
            <TenantCard
                    retail={newUnit.name}
                    x1090Sf={newUnit.squareFeet}
                    lastUpdated734PmOnMarch162020={newUnit.updatedAt}
                    group483Props={group483Props}
                    creationDate={newUnit.createdAt}
                    property={newProperty}
                    unit={newUnit}
            />
          </div>
          <div className="group-492">
            <div><ColorToggleButton></ColorToggleButton></div>
          </div>
          <div className="create-property-steps sqfeet lato-bold-mirage-18px">Update Unit Size (Square Feet)</div>
          <div className="step2-group">
          <div className="flex-col-2">
              <div className="overlap-group-12 border-1px-geyser">
                <input
                  className="dunkin-donuts lato-normal-spun-pearl-16px"
                  name="dunkindonuts"
                  placeholder={newUnit.squareFeet}
                  type={inputType}
                  onChange = {handleSquareFeetChange}
                  required
                />
              </div>
              
            </div>
          </div>

          <div className="create-property-steps upload lato-bold-mirage-18px">Add amendments or upload new lease</div>
          <div className="create-property-steps organize lato-bold-mirage-18px">Organize the files as needed</div>
          {/* <div className="rectangle-163"></div> */}
          <div className="rectangle-164 border-1px-ebb">
          </div>
          <div className="rectangle-166 border-1px-ebb"></div>
          {/* <div className="group-487">
            <p className="select-the-pdf-files-to-upload lato-bold-mirage-16px">{selectThePdfFilesToUpload}</p>
            <p className="or-drag-and-drop-them-here">{orDragAndDropThemHere}</p>
          </div> */}
          <div className="lease lato-bold-mirage-16px">{lease}</div>
          <div className="leaseCard lato-bold-mirage-16px"> <LeaseCard lease={unitLease}></LeaseCard></div>
          <div className="amendments lato-bold-mirage-16px">{amendments}</div>
          <div className="amendmentCards lato-bold-mirage-16px"><AmendmentCards amendments={unitAmendments}></AmendmentCards></div>
          {/* <p className="drag-and-drop-your-f lato-normal-mirage-14px">{dragAndDropYourF}</p> */}
          
          <PrimaryButton3
            addANewProperty={primaryButtonProps.addANewProperty}
            className={primaryButtonProps.className}
          />
        </div>
      </div>
    </div>
  );
}

export default UpdateTenant;
