import React, {useState} from 'react';
import {Grid, Box, Stack} from '@mui/material';
import TabOptions from "components/TabOptions";
import TabPanel from "components/TabPanel";
import SignIn from './SignIn';
import SignUp from './SignUp';
import styles from './styles.module.scss';

const Auth = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabOptions = [
    { name: 'Sign In', index: 0 },
    { name: 'Create Account', index: 1 },
  ];

  const handleSwitchTab = option => {
    setTabIndex(option.index);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stack height="100vh" backgroundColor="white" justifyContent="center" alignItems="center">
          <div className={styles.container}>
            <TabOptions options={tabOptions} className={styles.tabLayout}>
              {option => (
                <div
                  className={
                    option.index === tabIndex
                      ? styles.tabItemSelected
                      : styles.tabItem
                  }
                  onClick={() => handleSwitchTab(option)}
                >
                  {option.name}
                </div>
              )}
            </TabOptions>
            <TabPanel value={tabIndex} index={0}>
              <SignIn />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <SignUp />
            </TabPanel>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack height="100vh" backgroundColor="#2666E6">
        </Stack>
      </Grid>
    </Grid>
  )
};

export default Auth;
