import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {listClauses} from "graphql/queries";
import API, {graphqlOperation} from "@aws-amplify/api";
import { convertFromISODate } from "../../utils/date-util";
import styles from "./styles.module.scss";
import classNames from 'classnames';

function TenantCard(props) {
  const {retail, squareFeet, updatedAt, property, unit, onEditTenant} = props;
  const [tenantName, setTenantName] = useState(retail);
  const [sqft, setSQFT] = useState(squareFeet);
  const [expirationDate, setExpirationDate] = useState('');

  useEffect(() => {
    setTenantName(retail);
    setSQFT(squareFeet);
  }, [retail, squareFeet]);

  useEffect(() => {
    API.graphql(graphqlOperation(listClauses, {filter: {unitClauseId: {eq: unit.id}}})).then(clauseList => {
      const clauses = clauseList.data?.listClauses?.items;
      clauses.forEach(item => {
        if (item.clause === 'Gross Area') {
          setSQFT(Number(item.summary));
        } else if (item.clause === 'Lease Expiration') {
          setExpirationDate(item.summary);
        }
      });
    });
  }, [unit]);

  const handleEditTenant = e => {
    e.preventDefault();
    onEditTenant(unit);
  };

  return (
    // Maybe change this to a new page that is update tenant where they can add leases and such
    <Link to={`/abstract/${unit.id}`}>
      <div className={classNames(styles.container)}>
        <IconButton
          sx={{position: 'absolute', top: 10, right: 10}}
          onClick={handleEditTenant}>
          <EditIcon />
        </IconButton>
        <div className={styles.header}>
          <span className={styles.retail}>
            {sqft.toLocaleString()} SF
          </span>
          <h3>{tenantName}</h3>
        </div>
        <div className={styles.footer}>
          <p className={styles.expiration}>Expiration:</p>
          <p className={styles.expirationDate}>{convertFromISODate(expirationDate)}</p>
          <p className={styles.lastModifiedDate}>
            Last Modified: {convertFromISODate(updatedAt)}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default TenantCard;
