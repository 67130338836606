export const DEFAULT_CLAUSES = [
  {id: 0, type:'Basic Info', name: "Building", state:0, autogenerated: false, note: undefined},
  {id: 1, type:'Basic Info', name: "Building Type", state:0, autogenerated: false, note: undefined},
  {id: 2, type:'Basic Info', name: "Contact", state:0, autogenerated: false, note: undefined},
  {id: 3, type:'Basic Info', name: "Landlord", state:0, autogenerated: false, note: undefined},
  {id: 4, type:'Basic Info', name: "Lease Status", state:0, autogenerated: false, note: undefined},
  {id: 5, type:'Basic Info', name: "Tenant", state:0, autogenerated: false, note: undefined},
  {id: 6, type:'Key Terms', name: "Deposit", state:0, autogenerated: false, note: undefined},
  {id: 7, type:'Key Terms', name: "Execution Date", state:0, autogenerated: false, note: undefined},
  {id: 8, type:'Key Terms', name: "Gross Area", state:0, autogenerated: false, note: undefined},
  {id: 9, type:'Key Terms', name: "Lease Commencment", state:0, autogenerated: false, note: undefined},
  {id: 10, type:'Key Terms', name: "Lease Expiration", state:0, autogenerated: false, note: undefined},
  {id: 11, type:'Key Terms', name: "Rent Commencement Date", state:0, autogenerated: false, note: undefined},
  {id: 12, type:'Key Terms', name: "Rent Schedule", state:0, autogenerated: false, note: undefined},
  {id: 13, type:'Notes', name: "Admin Fee", state:0, autogenerated: false, note: undefined},
  {id: 14, type:'Notes', name: "Alterations", state:0, autogenerated: false, note: undefined},
  {id: 15, type:'Notes', name: "Assignment", state:0, autogenerated: false, note: undefined},
  {id: 16, type:'Notes', name: "Audit Rights", state:0, autogenerated: false, note: undefined},
  {id: 17, type:'Notes', name: "Commencement Date", state:0, autogenerated: false, note: undefined},
  {id: 18, type:'Notes', name: "Contration Option", state:0, autogenerated: false, note: undefined},
  {id: 19, type:'Notes', name: "Cotenancy", state:0, autogenerated: false, note: undefined},
  {id: 20, type:'Notes', name: "Default", state:0, autogenerated: false, note: undefined},
  {id: 21, type:'Notes', name: "Documents", state:0, autogenerated: false, note: undefined},
  {id: 22, type:'Notes', name: "Estoppel", state:0, autogenerated: false, note: undefined},
  {id: 23, type:'Notes', name: "Exclusive", state:0, autogenerated: false, note: undefined},
  {id: 24, type:'Notes', name: "Expansion Option", state:0, autogenerated: false, note: undefined},
  {id: 25, type:'Notes', name: "Expense Reimbursement - CAM", state:0, autogenerated: false, note: undefined},
  {id: 26, type:'Notes', name: "Expense Reimbursement - Insurance", state:0, autogenerated: false, note: undefined},
  {id: 27, type:'Notes', name: "Expense Reimbursement - Other", state:0, autogenerated: false, note: undefined},
  {id: 28, type:'Notes', name: "Expense Reimbursement - Taxes", state:0, autogenerated: false, note: undefined},
  {id: 29, type:'Notes', name: "Financial Statements", state:0, autogenerated: false, note: undefined},
  {id: 30, type:'Notes', name: "Free Rent", state:0, autogenerated: false, note: undefined},
  {id: 31, type:'Notes', name: "Holdover", state:0, autogenerated: false, note: undefined},
  {id: 32, type:'Notes', name: "Insurance", state:0, autogenerated: false, note: undefined},
  {id: 33, type:'Notes', name: "Late Fee", state:0, autogenerated: false, note: undefined},
  {id: 34, type:'Notes', name: "Month to Month", state:0, autogenerated: false, note: undefined},
  {id: 35, type:'Notes', name: "Parking", state:0, autogenerated: false, note: undefined},
  {id: 36, type:'Notes', name: "Parking Spaces", state:0, autogenerated: false, note: undefined},
  {id: 37, type:'Notes', name: "Percentage Rent", state:0, autogenerated: false, note: undefined},
  {id: 38, type:'Notes', name: "Permitted Use", state:0, autogenerated: false, note: undefined},
  {id: 39, type:'Notes', name: "Premises", state:0, autogenerated: false, note: undefined},
  {id: 40, type:'Notes', name: "Purchase Option", state:0, autogenerated: false, note: undefined},
  {id: 41, type:'Notes', name: "Relocation Option", state:0, autogenerated: false, note: undefined},
  {id: 42, type:'Notes', name: "Renewal Option", state:0, autogenerated: false, note: undefined},
  {id: 43, type:'Notes', name: "Rent Abatement", state:0, autogenerated: false, note: undefined},
  {id: 44, type:'Notes', name: "ROFO", state:0, autogenerated: false, note: undefined},
  {id: 45, type:'Notes', name: "Signage", state:0, autogenerated: false, note: undefined},
  {id: 46, type:'Notes', name: "SNDA", state:0, autogenerated: false, note: undefined},
  {id: 47, type:'Notes', name: "Sublease", state:0, autogenerated: false, note: undefined},
  {id: 48, type:'Notes', name: "Surrender", state:0, autogenerated: false, note: undefined},
  {id: 49, type:'Notes', name: "Tenant Improvements", state:0, autogenerated: false, note: undefined},
  {id: 50, type:'Notes', name: "Termination Option", state:0, autogenerated: false, note: undefined},
  {id: 51, type:'Notes', name: "Utilities", state:0, autogenerated: false, note: undefined},
  {id: 52, type:'Other', name: "Renewal Option", state:0, autogenerated: false, note: undefined},
];
