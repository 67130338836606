/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      nextToken
    }
  }
`;
export const getLease = /* GraphQL */ `
  query GetLease($id: ID!) {
    getLease(id: $id) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const listLeases = /* GraphQL */ `
  query ListLeases(
    $filter: ModelLeaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        textractBucket
        s3Path
        unit {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        name
        status
        createdAt
        updatedAt
        unitLeaseId
        owner
      }
      nextToken
    }
  }
`;
export const getAmendment = /* GraphQL */ `
  query GetAmendment($id: ID!) {
    getAmendment(id: $id) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const listAmendments = /* GraphQL */ `
  query ListAmendments(
    $filter: ModelAmendmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmendments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        textractBucket
        s3Path
        unit {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        name
        status
        createdAt
        updatedAt
        unitAmendmentsId
        owner
      }
      nextToken
    }
  }
`;
export const getClause = /* GraphQL */ `
  query GetClause($id: ID!) {
    getClause(id: $id) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
export const listClauses = /* GraphQL */ `
  query ListClauses(
    $filter: ModelClauseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClauses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clause
        summary
        highlights
        unit {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        createdAt
        confidence
        updatedAt
        unitClauseId
        owner
      }
      nextToken
    }
  }
`;
