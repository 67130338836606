import {createTheme} from "@mui/material/styles";
import {themePalette} from "./palette";
import {themeButton} from "./button";

// Create a theme instance.
export const theme = createTheme({
  palette: themePalette,
  components: {
    MuiButton: themeButton,
  },
});
