import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const TabOptions = ({ options, children, className = '' }) => {
  return (
    <div className={classNames(styles.tabOptionsContainer, className)}>
      {options.map(option => (
        <div
          key={option.name}
          className={classNames(
            styles.tab,
            { [styles.tabBackgroundActive]: option.isActive },
            {
              [styles.tabActive]: option.isActive,
            }
          )}
        >
          {children(option)}
        </div>
      ))}
    </div>
  );
};

TabOptions.propTypes = {
  children: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TabOptions;
