import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Box, Stack, Typography, IconButton} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Viewer} from '@react-pdf-viewer/core';
import {zoomPlugin} from '@react-pdf-viewer/zoom';
import {selectionModePlugin} from '@react-pdf-viewer/selection-mode';
import {searchPlugin} from '@react-pdf-viewer/search';
import {highlightPlugin, RenderHighlightTargetProps} from '@react-pdf-viewer/highlight';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import sortBy from "lodash/sortBy";
import * as d3 from "d3";
import {toast} from 'react-toastify';
import {useDrag} from 'react-dnd'
import API, {graphqlOperation} from "@aws-amplify/api";
import {Storage} from "aws-amplify";
import {createClause, updateClause, updateProperty, updateUnit} from "graphql/mutations";
import {getUnit, listClauses, getClause} from "graphql/queries";
import NavLayout from "components/NavLayout";
import {PDFSearchBar} from "components/PDFSearchBar";
import {StyledTabs, StyledTab} from "components/Tabs";
import AbstractSidebar from "./components/AbstractSidebar";
import TabPanel from "components/TabPanel";
import AutogeneratedReview from "components/AutogeneratedReview";
import {DEFAULT_CLAUSES} from "data/clauses";
import styles from "./styles.module.scss";
import PDFRenameView from "./components/PDFRenameView";
import {ZoomInOut} from "./components/ZoomInOut";
import selected from './selected.csv'; 

const AbstractPage = () => {
  const {tenantId} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [unitLoaded, setUnitLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [propertyUnit, setPropertyUnit] = useState({});
  const [unitLease, setUnitLease] = useState(undefined);
  const [unitAmendments, setUnitAmendments] = useState(undefined);
  const [leasePdfUrl, setLeasePdfUrl] = useState('');
  const [amendmentPdfUrl, setAmendmentPdfUrl] = useState('');
  const [amendmentItem, setAmendmentItem] = useState();
  const [showRenameModal, setShowRenameModal] = useState(false);

  const [highlightedInfo, setHighlightedInfo] = useState({});
  const [highlightList, setHighlightList] = useState([]);
  const [message, setMessage] = React.useState('');
  const [clauses, setClauses] = useState([]);
  const [shouldInitHighlights, setShouldInitHighlights] = useState(false);
  const [showAutogeneratedReview, setShowAutogeneratedReview] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const noteEles = new Map();

  const selectionModePluginInstance = selectionModePlugin();
  const searchPluginInstance = searchPlugin();
  const {clearHighlights} = searchPluginInstance;
  const matches = useRef();
  const clauseRef = useRef();
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'Clause',
    item: 1,
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        clauseRef.current.click();
      }
    }
  }))

  useEffect(() => {
    const intervalId = setInterval(async () => {  //assign interval to a variable to clear it.
      if (await checkAbstractStatus()) {
        clearInterval(intervalId);
      }
    }, 5000)
    const initFunc = async () => {
      if (await checkAbstractStatus()) {
        clearInterval(intervalId);
      }
    };
    initFunc();
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (tabIndex !== 0) {
      if (unitAmendments !== undefined) {
        const amendmendId = unitAmendments[tabIndex-1].id
        getAmendmentPDF(amendmendId);
      }
    }
    
  }, [tabIndex, unitLease, unitAmendments]);

  useEffect(()=>{
    if (unitLease !== undefined && unitLoaded) {
      getTextractClauses();
    }
  }, [unitLoaded, unitLease]);

  const checkAbstractStatus = async () => {
    const result2 = await API.graphql(graphqlOperation(getUnit, {id: tenantId}));
    const newUnit = result2.data.getUnit;
    const leaseInfo = newUnit.lease;
    var status = true;
    if (leaseInfo.items.length > 0) {
      if (leaseInfo.items[0].status !== 'Complete') {
        status = false;
      }
    }
    const amendmentInfo = newUnit.amendments; 
    if (amendmentInfo.items.length > 0) {
      amendmentInfo.items.forEach(item => {
        if (item.status !== 'Complete') {
          status = false;
        }
      });
    }
    if (status) {
      getUnitInfo(newUnit)
      setLoading(false);
      const saved = localStorage.getItem(newUnit.id) || false;
      if (!saved) {
        setShowAutogeneratedReview(true);
      }
    }
    return status;
  };

  const getTextractClauses = async () => {
    const currentClauses = await API.graphql(graphqlOperation(listClauses, {
        filter: {
          unitClauseId: {
            eq: propertyUnit.id
          }
        }
    }));
    if(currentClauses.data.listClauses.items.length > 0){
      console.log("Already auto found clauses for this unit");
      return
    }
    else{
      console.log("Building auto generated clauses");
      await Storage.list(unitLease.textractBucket)
        .then(result => processStorageList(result))
        .catch(err => console.log(err));
    }
  };

  const processStorageList = async (result) => {
    let lifoList = sortBy(result, ["lastModified"]).reverse();
    let queryType = "";
    let queryAnswer = "";
    let queryConfidence = 0;
    let queryGeometry = "";
    let pageNum = "";
    let fileIds = [];
  
    const newClauses = [...clauses];
    var initialVals = {"input": {clause: "", unitClauseId: "", summary: "", highlights: ``, confidence: 0}};
    const res = await d3.csv(selected);
    var dataset = {};
    for (var i = 0; i < res.length; i++){
        dataset[res[i].Name] = initialVals;
    }     
    fileIds.push(unitLease.id)
    unitAmendments?.forEach(async (amendment) => {
      fileIds.push(amendment.id)
    });
    await Promise.all(lifoList.map(async (res) => {
      const result2 = await Storage.get(res.key, { level: 'public', download: true });
      const parsed = res.key.split( '/' ).slice( 0, -1 ).join( '/' );
      const parsed1 = "PDF Uploads/" + parsed.substring(9, parsed.length-65)
      const match = fileIds.find(element => {
        if (element.includes(parsed1)) {
          return true;
        }
      });
      try {
        var jsonDoc = await new Response(result2.Body).json();
        await Promise.all(jsonDoc.Blocks.map(async (element) => {
          const queryResult = {element}.element.BlockType;
          if (queryResult === "QUERY"){
            queryType = {element}.element.Query.Alias
          }
          if(queryResult === "QUERY_RESULT"){
            queryAnswer = {element}.element.Text;
            if(queryAnswer.length < 9 || !queryAnswer in dataset) return;
            const index = newClauses.findIndex( item => {
              return item.name === queryType;
            });
            pageNum = ({element}.element.Page - 1);
            queryConfidence = parseInt({element}.element.Confidence, 10);
            queryGeometry = {element}.element.Geometry;
            let top = (queryGeometry.BoundingBox.Top * 100).toString();
            let left = (queryGeometry.BoundingBox.Left * 100).toString();
            let width = (queryGeometry.BoundingBox.Width * 100).toString();
            let height = (queryGeometry.BoundingBox.Height * 100).toString();
            if (height > 20){
              height = 1.5;
            };
            if((queryType === "Lessee" || queryType === "Lendee" ||queryType === "Grantee" || queryType === "Assignee" || queryType === "Tenant")){
              // if(queryConfidence > dataset["Tenant"].input.confidence){
                // console.log("better clause found for Tenant on page: ", pageNum, queryType, queryAnswer, queryConfidence, queryGeometry);
                dataset["Tenant"] = {"input": {
                  clause: "Tenant",
                  unitClauseId: propertyUnit.id,
                  summary: queryAnswer,
                  highlights: `[{"highlightAreas":[{"top":${top},"left":${left},"pageIndex":${pageNum},"width":${width},"height":${height}}],"pdfId":"${match}"}]`,
                  confidence: queryConfidence,
                }};
              // }
            }
            else if((queryType === "Lessor" || queryType === "Lender" ||queryType === "Grantor" || queryType === "Assignor" || queryType === "Landlord")){
              // if(queryConfidence > dataset["Landlord"].input.confidence){
                // console.log("better clause found for Landlord on page: ", pageNum, queryType, queryAnswer, queryConfidence, queryGeometry);
                dataset["Landlord"] = {"input": {
                  clause: "Landlord",
                  unitClauseId: propertyUnit.id,
                  summary: queryAnswer,
                  highlights: `[{"highlightAreas":[{"top":${top},"left":${left},"pageIndex":${pageNum},"width":${width},"height":${height}}],"pdfId":"${match}"}]`,
                  confidence: queryConfidence,
                }};
              // }
            }
            else{
              // if(queryConfidence > dataset[queryType].input.confidence){
                // console.log("better clause found for ", queryType, " on page: ", pageNum, queryType, queryAnswer, queryConfidence, queryGeometry);
                dataset[queryType] = {"input": {
                  clause: queryType,
                  unitClauseId: propertyUnit.id,
                  summary: queryAnswer,
                  highlights: `[{"highlightAreas":[{"top":${top},"left":${left},"pageIndex":${pageNum},"width":${width},"height":${height}}],"pdfId":"${match}"}]`,
                  confidence: queryConfidence,
                }};
              // }
            } 
          }
        }));
      } catch (e) {
        console.log(e);
      }
    }));
    for (const [key, value] of Object.entries(dataset)) {
      if (value.input.confidence > 0){

        const index = newClauses.findIndex( item => {
          return item.name;
        });
        const note = {
          content: value.input.summary,
          highlights: JSON.parse(value.input.highlights),
        };
        newClauses[index].note = note;
        newClauses[index].autogenerated = true;
        const input = value.input;
        const result = await API.graphql(graphqlOperation(createClause, { input }));
        const createdClause = result.data.createClause;
        console.log("AUTO CREATE CLAUSE RESULT: ", createdClause);
      }
    }
    setClauses(newClauses);
    checkAbstractStatus();
  }

  const getAmendmentPDF = async (amendmendId) => {
    const pdfUrl = await getPdfUrl(amendmendId);
    setAmendmentPdfUrl(pdfUrl);
  };
  
  const getUnitInfo = async (newUnit) => {
    setPropertyUnit(newUnit);
    const leaseInfo = newUnit.lease;
    if (leaseInfo.items.length > 0) {
      setUnitLease(leaseInfo.items[0]);
      const pdfUrl = await getPdfUrl(leaseInfo.items[0].id);
      setLeasePdfUrl(pdfUrl);
    }
    const amendmentInfo = newUnit.amendments; 
    if (amendmentInfo.items.length > 0) {
      setUnitAmendments(amendmentInfo.items);
    }
    const clauseList = newUnit.clause.items;
    if (clauseList.length > 0) {
      const defaultClauses = [...DEFAULT_CLAUSES];
      defaultClauses.forEach(newClause => {
        newClause.note = undefined;
        newClause.autogenerated = false;
      });
      clauseList.forEach(savedClause => {
        const index = defaultClauses.findIndex( item => {
          return item.name === savedClause.clause;
        });
        const note = {
          clauseId: savedClause.id,
          content: savedClause.summary,
          highlights: JSON.parse(savedClause.highlights),
          lastModifiedAt: savedClause.updatedAt
        };
        defaultClauses[index].note = note;
      });
      setClauses(defaultClauses);
    } else {
      const defaultClauses = [...DEFAULT_CLAUSES];
      defaultClauses.forEach(newClause => {
        newClause.note = undefined;
      });
      setClauses(defaultClauses);
    }
    setUnitLoaded(true);
  }

  const getPdfUrl = async (id) => {
    const pdfUrl = await Storage.get(id);
    return pdfUrl;
  };

  const handleTabMenu = (e, item) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setAmendmentItem(item);
  };

  const handleRenameModal = () => {
    setShowRenameModal(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRenameChanged = (updatedItem) => {
    const updatedItems = [...unitAmendments];
    const id = updatedItems.findIndex(item => item.id === updatedItem.id);
    if (id >= 0) {
      updatedItems[id] = updatedItem;
      setUnitAmendments(updatedItems);
    }
    setShowRenameModal(false);
  };

  const handleChangeTab = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleCloseAutogeneratedReview = (close) => {
    if (!close) {
      localStorage.setItem(propertyUnit.id, JSON.stringify(close));
    }
    setShowAutogeneratedReview(close);
  }

  const handleClauseDialog = useCallback((clauseIndex, mode) => {
    const newClauses = [...clauses];
    newClauses.forEach(clause => {
      clause.state = 0;
    });
    newClauses[clauseIndex].state= mode;
    setClauses(newClauses);
    const note = newClauses[clauseIndex].note;
    if (note !== undefined) {
      setMessage(note.content);
      setHighlightList(note.highlights);
    } else {
      setMessage('');
      setHighlightList([]);
    }
    setShouldInitHighlights(true);
    // clear search highlights
    clearHighlights();
    matches.current = [];
  }, [clauses, clearHighlights]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const _updateProperty = (propertyId) => {
    const input = {
      id: propertyId,
    };
    API.graphql(graphqlOperation(updateProperty, { input }));
  };

  const _updateUnit = (unitId) => {
    const input = {
      id: unitId,
    };
    API.graphql(graphqlOperation(updateUnit, { input }));
    _updateProperty(propertyUnit.property.id);
  };

  const handleAddToAbstract = useCallback(async (clauseIndex) => {
    const newClauses = [...clauses];
    newClauses.forEach(clause => {
      clause.state = 0;
    });

    const savedClause = newClauses[clauseIndex];

    let note = {
      ...savedClause.note,
      lastModifiedAt: Date.now()
    };

    if (savedClause.note !== undefined) {
      var input = {
        clause: savedClause.name,
        summary: savedClause.note.content,
        highlights: JSON.stringify(savedClause.note.highlights),
        unitClauseId: propertyUnit.id,
      }
      const result = await API.graphql(graphqlOperation(createClause, { input }));
      const createdClause = result.data.createClause;
      note = {
        clauseId: createdClause.id,
        content: createdClause.summary,
        highlights: JSON.parse(createdClause.highlights),
        lastModifiedAt: createdClause.updatedAt
      };
    }
    newClauses[clauseIndex].autogenerated = false;
    newClauses[clauseIndex].note= note;
    setClauses(newClauses);
    // update unit and property
    _updateUnit(tenantId);
  }, [_updateUnit, clauses, propertyUnit.id, tenantId]);

  const handleSaveChange = useCallback(async (clauseIndex) => {
    if (message !== "") {
      const newClauses = [...clauses];
      newClauses.forEach(clause => {
        clause.state = 0;
      });

      let note = {
        content: message,
        highlights: highlightList,
        lastModifiedAt: Date.now()
      };

      const savedClause = newClauses[clauseIndex];

      if (savedClause.note === undefined || savedClause.autogenerated) {
        var input = {
          clause: savedClause.name,
          summary: message,
          highlights: JSON.stringify(highlightList),
          unitClauseId: propertyUnit.id,
        }
        const result = await API.graphql(graphqlOperation(createClause, { input }));
        const createdClause = result.data.createClause;
        note = {
          clauseId: createdClause.id,
          content: createdClause.summary,
          highlights: JSON.parse(createdClause.highlights),
          lastModifiedAt: createdClause.updatedAt
        };
      } else {
        const input = {
          id: savedClause.note.clauseId,
          summary: message,
          highlights: JSON.stringify(highlightList),
        };
        const result = await API.graphql(graphqlOperation(updateClause, { input }));
        const updatedClause = result.data.updateClause;
        note = {
          clauseId: updatedClause.id,
          content: updatedClause.summary,
          highlights: JSON.parse(updatedClause.highlights),
          lastModifiedAt: updatedClause.updatedAt
        };
      }
      newClauses[clauseIndex].note= note;
      newClauses[clauseIndex].autogenerated= false;
      setClauses(newClauses);
      if (highlightedInfo.cancel !== undefined) {
        highlightedInfo?.cancel();
      }
      // update unit and property
      _updateUnit(tenantId);
    }
  }, [_updateUnit, clauses, highlightList, highlightedInfo, message, propertyUnit.id, tenantId]);

  const getLeaseOrAmendmentId = (tabIndex) => {
    if (tabIndex === 0) {
      return unitLease.id;
    } else {
      return unitAmendments[tabIndex - 1].id;
    }
  };

  const getTabIndexFromId = (pdfId) => {
    if (pdfId === unitLease.id) {
      return 0
    }
    for (var cnt = 0; cnt < unitAmendments.length; cnt++) {
      if (unitAmendments[cnt].id === pdfId) {
        return cnt + 1;
      }
    }
    return 0;
  }

  const handleJumpHighlight = (clauseIndex) => {
    const clause = clauses[clauseIndex];
    if (clause.note.highlights?.length > 0) {
      const firstHighlight = clause.note.highlights[0];
      const newTabIndex = getTabIndexFromId(firstHighlight?.pdfId);
      setTabIndex(newTabIndex);
      jumpToHighlightArea(firstHighlight.highlightAreas[0]);
    }
  };

  const checkClauseOpened = () => {
    for (var cnt = 0; cnt < clauses.length; cnt++) {
      if (clauses[cnt].state === 1) {
        return true;
      }
    };
    return false;
  };

  const onMatchChanged = useCallback(result => {
    matches.current = result;
  }, []);

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
    const startHighlight = () => {
      if (matches.current?.length > 0) {
        clearHighlights();
        matches.current = [];
        toast.warn("Please try again", {position: "top-right", autoClose: 2000, hideProgressBar: true});
      } else {
        if (checkClauseOpened()) {
          navigator.clipboard.writeText(props.selectedText);
          setHighlightedInfo(props);

          let newMessage = message === '' ?  props.selectedText : message.concat(`\n${props.selectedText}`);
          let highlights= [...highlightList];
          if (shouldInitHighlights) {
            setShouldInitHighlights(false);
            newMessage = props.selectedText;
            highlights = [];
          }
          const highlight = {
            pdfId: getLeaseOrAmendmentId(tabIndex),
            highlightAreas: props.highlightAreas
          };
          highlights.push(highlight);
          setMessage(newMessage);
          setHighlightList(highlights);
        } else {
          toast.error("Please select the clause.", {position: "top-right", autoClose: 2000, hideProgressBar: true});
        }
      }
      props.cancel();
    };

    return (
      <div
        ref={clauseRef}
        style={{
          background: '#eee',
          display: 'flex',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: 'translate(0, 8px)',
          zIndex: 10,
      }}
      onClick={startHighlight}
    >
      <div ref={drag} className={styles.highlightTooltip}>
        Drag and Drop Clause
      </div>
    </div>
    );
  };

  const jumpToNote = (id) => {
    if (noteEles.has(id)) {
        noteEles.get(id)?.scrollIntoView();
    }
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {clauses?.filter(clause => clause.note !== undefined).map((clause, index) => (
        <React.Fragment key={`highlight-${clause.id}`}>
          {clause.note.highlights
            .filter(item => item.pdfId === getLeaseOrAmendmentId(tabIndex))
            .map(highlight =>
              highlight.highlightAreas.filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => (
                <div
                  key={idx}
                  style={Object.assign(
                      {},
                      {
                          background: 'yellow',
                          opacity: 0.4,
                      },
                      props.getCssProperties(area, props.rotation)
                  )}
                  onClick={() => jumpToNote(clause.id)}
                  ref={(ref) => {
                    noteEles.set(clause.id, ref);
                  }}
                />
            )))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget, renderHighlights
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const zoomPluginInstance = zoomPlugin();

  return (
    <NavLayout>
      <Box padding="20px 0 0 40px" background="#F8F8F8">
        {loading ? (
          <div className={styles.distractor}>
            <div className={styles.loadingSpinner} />
            <p>We are reading your documents and getting them ready,<br/>this may take up to five minutes</p>
          </div>
        ) : (
          <Stack spacing={4} direction="row">
            <Stack flex={1}>
              <Stack direction="row">
                <Stack direction="row" alignItems="center" marginTop="10px" onClick={handleBack} sx={{cursor: 'pointer'}}>
                  <IconButton>
                    <img src="/img/arrow-left-icon@2x.svg" alt="arrow-left" />
                  </IconButton>
                  <Typography sx={{fontFamily: "Roboto", fontSize: "14px", fontWeight: 400, color: "black"}}>
                    Back to all Tenants
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{fontSize: "30px", fontWeight: 700, color: "black"}}>{propertyUnit.name}</Typography>
                <PDFSearchBar
                  placeholder="Search lease and amendments"
                  tabIndex={tabIndex}
                  onMatchChanged={onMatchChanged}
                  searchPluginInstance={searchPluginInstance}/>
              </Stack>
              <StyledTabs value={tabIndex} variant="scrollable" onChange={handleChangeTab}>
                <StyledTab darkmode="true" label="Lease Agreement" />
                {unitAmendments?.length > 0 &&
                  unitAmendments?.map((item, index) => (
                    <StyledTab key={index} darkmode="true" label={item.name} onContextMenu={(e) => handleTabMenu(e, item)} />
                  ))}
                {/* <StyledTab darkmode="true" label="Add New Document" /> */}
              </StyledTabs>
              <TabPanel value={tabIndex} index={0}>
                <Box
                  marginTop="16px"
                  width="100%"
                  height="calc(100vh - 260px);"
                  sx={{position: 'relative', background: "white", boxShadow: "0px 0px 25px rgba(126, 126, 126, 0.1);", borderRadius: "4px"}}>
                  {leasePdfUrl !== '' && (
                  <Viewer
                    plugins={[selectionModePluginInstance, searchPluginInstance, highlightPluginInstance, zoomPluginInstance]}
                    scrollMode
                    fileUrl={leasePdfUrl} />
                  )}
                  <ZoomInOut zoomPluginInstance={zoomPluginInstance} />
                </Box>
              </TabPanel>
              {unitAmendments?.map((item, index) => (
              <TabPanel key={index} value={tabIndex} index={index+1}>
                <Box
                  marginTop="16px"
                  width="100%"
                  height="calc(100vh - 260px);"
                  sx={{position: 'relative', background: "white", boxShadow: "0px 0px 25px rgba(126, 126, 126, 0.1);", borderRadius: "4px"}}>
                  {amendmentPdfUrl !== '' && (
                  <Viewer
                    plugins={[selectionModePluginInstance, searchPluginInstance, highlightPluginInstance, zoomPluginInstance]}
                    scrollMode
                    fileUrl={amendmentPdfUrl} />
                  )}
                  <ZoomInOut zoomPluginInstance={zoomPluginInstance} />
                </Box>
              </TabPanel>
              ))}
              {/* <TabPanel value={tabIndex} index={2}>
                <Box marginTop="16px" width="100%" height="100vh" sx={{background: "white", boxShadow: "0px 0px 25px rgba(126, 126, 126, 0.1);", borderRadius: "4px"}}>
                </Box>
              </TabPanel> */}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleRenameModal}>Rename</MenuItem>
              </Menu>
            </Stack>
            <AbstractSidebar
              clauses={clauses}
              propertyUnit={propertyUnit}
              message={message}
              handleMessageChange={handleMessageChange}
              handleSaveChange={handleSaveChange}
              handleAddToAbstract={handleAddToAbstract}
              handleClauseDialog={handleClauseDialog}
              handleJumpHighlight={handleJumpHighlight}
            />
          </Stack>
        )}
        {showAutogeneratedReview && (
          <AutogeneratedReview
            setOpenPopup={handleCloseAutogeneratedReview}
          />
        )}
        {showRenameModal && (
          <PDFRenameView
            item={amendmentItem}
            setOpenRename={setShowRenameModal}
            onChanged={handleRenameChanged}
          />
        )}
      </Box>
    </NavLayout>
  )
};

export default AbstractPage;
