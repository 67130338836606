
import React, {useState} from "react";
import classNames from 'classnames';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';

import styles from "./styles.module.scss";
import DropZone from "../../../../components/DropZone";
import FileUploadItem from "../../../../components/FileUploadItem";
import AmendmentItem from "../../../../components/AmendmentItem";

const StepThird = ({files, handleSelectFile, handleRenameFile, handleRemove, handleEdit, onDragEnd}) => {

  return (
    <div className={styles.parentWrapper}>
      <div className={classNames(styles.container, styles.mediumSize)}>
        <h3>Upload Amendments</h3>
        <p className={styles.description}>Drag and drop, search, or add a URL to upload any amendments.</p>
        <div className={styles.inputWrapper}>
          <DropZone fileTypes={["pdf"]} multiple={true} handleChange={handleSelectFile}>
            <div className={styles.dropzone}>
              <div className={styles.items}>
                <img src="/img/ic-document.svg" alt="document" />
                <p className={styles.dropzoneTitle}>Select the PDF files to upload</p>
                <p className={styles.dropzoneDescription}>Or drag and drop them here.</p>
                <div className={styles.selectButton}>
                  Select Documents
                </div>
              </div>
            </div>
          </DropZone>
        </div>
        <div className={styles.inputWrapper}>
          <p className={styles.label}>Uploaded Documents ({files.length})</p>
          <div className={styles.fileItems}>
            {files.map((file, index) =>
              <FileUploadItem
                key={`fileupload-${index}`}
                progress={file.progress}
                fileIndex={index}
                fileName={file.file.name}
                fileSize={file.file.size}
                handleRemove={handleRemove}
              />)}
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.mediumSize)}>
        <h3>Organize Your Amendments</h3>
        <p className={styles.description}>Please organize your amendments in the order that you’d like to view them, starting with the most recent amendment.</p>
        <div className={styles.inputWrapper}>
          <div className={styles.amendItems}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {files.map((file, index) =>
                      <AmendmentItem
                        key={`amendment-${index}`}
                        fileIndex={index}
                        editMode={file.edit}
                        fileName={file.file.name}
                        fileSize={file.file.size}
                        handleRenameFile={handleRenameFile}
                        handleEdit={handleEdit}
                        handleRemove={handleRemove}
                      />)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>

  )
};

export default StepThird;
