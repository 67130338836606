import React, {useEffect, useState} from 'react';
import { Draggable } from 'react-beautiful-dnd';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import InputWithBorder from '../InputWithBorder';

const AmendmentItem = ({
  className,
  fileIndex,
  fileName,
  fileSize,
  editMode,
  handleRemove,
  handleEdit,
  handleRenameFile,
}) => {
  const [newFileName, setNewFileName] = useState(fileName);
  
  const getFileSizeInKB = size => {
    const fileSizeInKb = fileSize / 1024;
    return fileSizeInKb.toFixed(2);
  };
  useEffect(() => {
    setNewFileName(fileName);
  }, [fileName]);

  const handleChange = (event) => {
    const value = event.target.value;
    setNewFileName(value);
  };

  return (
    <Draggable draggableId={`amendment-${fileIndex}`} index={fileIndex}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames(styles.container, editMode && styles.blueBackground, className)}>
          {editMode ?
          (<div className={styles.editContainer}>
            <div className={styles.topContainer}>
              <p className={styles.amendmentName}>{`Amendment ${fileIndex+1}`}</p>
              <IconButton sx={{color: "white", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} onClick={() => handleRemove(fileIndex)}>
                <CloseIcon sx={{width: "22px", height: "22px"}}/>
              </IconButton>
            </div>
            <div className={styles.bottomContainer}>
              <InputWithBorder value={newFileName} onChange={handleChange} className={styles.input}/>
              <Button
                disableElevation
                variant="contained"
                onClick={() => handleRenameFile(fileIndex, newFileName)}
                sx={{
                  textTransform: "none",
                  background: "white",
                  color: "#2666E6",
                  "&:hover": {
                    background: "#cecece",
                  }
                }}>
                Save
              </Button>
            </div>
          </div>
          ) : 
          (<div className={styles.normalContainer}>
            <div className={styles.firstContainer}>
              <img src={`/img/icon-list.png`} width="10px" height="16px" alt="icon-list" />
              <div className={styles.fileInfo}>
                <p className={styles.amendmentName}>{`Amendment ${fileIndex+1}`}</p>
                <p className={styles.fileName}>{fileName}</p>
                <p className={styles.fileSize}>{getFileSizeInKB(fileSize)}kb</p>
              </div>
            </div>
            <div>
              <IconButton sx={{color: "#2666E6", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} onClick={() => handleEdit(fileIndex)}>
                <EditIcon sx={{width: "22px"}}/>
              </IconButton>
              <IconButton sx={{color: "#2666E6", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} onClick={() => handleRemove(fileIndex)}>
                <CloseIcon sx={{width: "22px"}}/>
              </IconButton>
            </div>
          </div>
          )}
        </div>)}
      </Draggable>
  )
};

export default AmendmentItem;
