import React from "react";

import styles from "./styles.module.scss";

const AutogeneratedReview = ({
  setOpenPopup,
}) => {
  
  return (
    <div className={styles.popupContainer}>
      <div className={styles.container}>
        <div className={styles.description}>
          <h3>Clauses have been found</h3>
          <p>We’ve done the highlighting for you. Please review the terms we’ve found to add them to your abstract.</p>
        </div>
        <div className={styles.reviewButton} onClick={(e) => setOpenPopup(false)}>
          Review Clauses
        </div>        
      </div>
    </div>
  )
};

export default AutogeneratedReview;
