import React, {useState} from 'react';
import styles from './styles.module.scss';
import _ from 'lodash';
import classNames from 'classnames';

const InputWithBorder = ({
  className,
  disabled = false,
  error = false,
  errorMessage,
  type,
  onSubmit = _.noop,
  reference,
  min,
  max,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const checkOnKeyPress = event => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }
  return (
    <div className={styles.container}>
      <input
        className={classNames(styles.input, className, error && styles.error)}
        disabled={disabled}
        type={type === 'password' ? showPassword ? 'text' : type : type}
        onKeyPress={checkOnKeyPress}
        ref={reference}
        {...props}
      />
      {type === 'password' && (
        <div className={styles.button} onClick={() => setShowPassword(prev => !prev)}>
          <img src={showPassword ? "/img/ic-hide.svg" : "/img/ic-eye.svg"} alt="ic-eye" />
        </div>
      )}
      {error && <p className={styles.errorLabel}>{errorMessage ? errorMessage : 'This field is required'}</p>}
    </div>
  )
}

export default InputWithBorder;
