const primaryButton1Data = {
  addANewProperty: "CREATE AND ADD TENANT",
  className: "primary-button-1",
};

const group4741Data = {
  propertyName: "Property name",
  inputPlaceholder: "Smithaven Outlet Mall",
};

const group4742Data = {
  propertyName: "Total property size (in sq.ft.)",
  inputPlaceholder: "78,000",
  className: "group-475",
};

export const createPropertyData = {
  rectangle150: "",
  group349: "/img/group-349@1x.png",
  createANewProperty: "Create a new property",
  city: "CITY",
  inputType1: "text",
  inputPlaceholder1: "Brookhaven",
  state: "STATE",
  inputType2: "text",
  inputPlaceholder2: "NY",
  zipCode: "ZIP CODE",
  inputType3: "text",
  inputPlaceholder3: "43004",
  propertyTypeTitle: "Property type",
  allFieldsAreRequired: "ALL FIELDS ARE REQUIRED",
  mdu: "MDU",
  office: "Office",
  retail: "Retail",
  industrial: "Industrial",
  propertyAddress: "Property address",
  streetAddress: "STREET ADDRESS",
  inputType4: "text",
  inputPlaceholder4: "222 Harrison Street",
  primaryButtonProps: primaryButton1Data,
  group4741Props: group4741Data,
  group4742Props: group4742Data,
};

const primaryButton2Data = {
  addANewProperty: "ADD A NEW PROPERTY",
  className: "primary-button-1",
};

const propertyCardData = {
  categ: "CATEG",
  sqft: "NUMBER SF",
  propertyName: "Property Name",
  propertyAddressLin: <>Property Address line<br />Property City, Property State</>,
  lastUpdatedTimeOnDateMmDdYyyy: "Time on Date MM/DD/YYYY",
};

const propertyCard21Data = {
  sqft: "177,500 SF",
  propertyName: "Echelon Two Properties",
  propertyAddressLin: <>1205 Roosevelt Avenue<br />Carteret, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "11:12am on March 14, 2020",
};

const propertyCard22Data = {
  sqft: "2,000 SF",
  propertyName: "Plaza of Egg Harbor",
  propertyAddressLin: <>802 White Horse Pike<br />Egg Harbor City, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "12:04pm on March 4, 2020",
  className: "property-card-1-2",
};

const propertyCard31Data = {
  sqft: "225,000 SF",
  propertyName: "Deptford Plaza Shopping Center",
  propertyAddressLin: <>1450 Clements Bridge Road<br />Woodbury, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "11:02am on March 15, 2020",
};

const propertyCard23Data = {
  sqft: "18,000 SF",
  propertyName: "Meryl Shopping Center",
  propertyAddressLin: <>3910 S Rural Road<br />Tempe Gardens, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "10:10pm on March 16, 2020",
  className: "property-card-1",
};

const propertyCard41Data = {
  sqft: "58,250 SF",
  propertyName: "St. Luke of Augustine Medical Office Cente...",
  propertyAddressLin: <>6 Commerce Drive<br />Cranford, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "6:13pm on March 20, 2020",
};

const propertyCard32Data = {
  sqft: "200,000 SF",
  propertyName: "Echelon One Properties",
  propertyAddressLin: <>801 Roosevelt Avenue<br />Carteret, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "9:57am on March 15, 2020",
  className: "property-card-3",
};

const propertyCard42Data = {
  sqft: "78,000 SF",
  propertyName: "Mission 50 Workspaces",
  propertyAddressLin: <>50 Harrison Street<br />Hoboken, NJ</>,
  lastUpdatedTimeOnDateMmDdYyyy: "10:10pm on March 16, 2020",
  className: "property-card-4",
};

export const propertyViewData = {
  numberOfPropertyHeader: "8 properties",
  sortBy: "Sort by:",
  selected: "Alphabetical",
  notSelected: "Last updated",
  primaryButtonProps: primaryButton2Data,
  propertyCardProps: propertyCardData,
  propertyCard21Props: propertyCard21Data,
  propertyCard22Props: propertyCard22Data,
  propertyCard31Props: propertyCard31Data,
  propertyCard23Props: propertyCard23Data,
  propertyCard41Props: propertyCard41Data,
  propertyCard32Props: propertyCard32Data,
  propertyCard42Props: propertyCard42Data,
};

const topNav2Data = {
  overlapGroup1: "",
  whitemarshPro: "WHITEMARSH PRO",
};

const backButton1Data = {
  backToAllTenants: "BACK TO ALL TENANTS",
};

const group4502Data = {
  className: "group-450-1",
};

const tabsOpenData = {
  surname: "Key terms",
};

const tabsUnselected1Data = {
  notes: "Notes",
};

const tabsUnselected2Data = {
  notes: "Basic Info",
  className: "tabs-unselected-1",
};

const tabsUnselected3Data = {
  notes: "Other",
  className: "tabs-unselected-2",
};

const primaryButton22Data = {
  addANewProperty: "ADD A CLAUSE",
};

export const leaseViewData = {
  originalLeasePdf: "ORIGINAL LEASE.PDF",
  address1: "1 of  93",
  address2: "2 of  93",
  loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut fermentum libero. Cras elementum risus a orci auctor pretium. Nam in malesuada mi, sit amet pharetra velit. Sed in massa sem. Sed ac turpis faucibus, feugiat quam nec, placerat augue. Nam eros nulla, luctus ac nisi nec, convallis varius ante. Suspendisse viverra, leo sed auctor viverra, turpis neque sodales sem, a tempor velit nisl ut ipsum. Morbi tempus risus a elementum lacinia. Suspendisse sed leo odio. Pellentesque turpis lacus, cursus a viverra non, ullamcorper in elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer aliquet turpis tincidunt porta gravida.",
  noteThisShopping: "Note: This Shopping Center Lease Summary is provided solely as a convenience to summarize certain Lease provisions and not as a complete summary of all material terms and conditions of the Lease. In the event of any inconsistency between any information shown on this Shopping Center Lease Summary and the provisions of the Lease, the provisions of the Lease shall govern.",
  dateOfLeaseLandl: <>Date of Lease: <br />Landlord: <br />Tenant: <br />Tenant’s Trade Name: <br />Guarantor: <br />Permissible Use of Premises: <br />Gross Usable Area of Premises: <br />Gross Leasable Area of Premises: <br />Minimum Business Hours: <br /><br /><br />Estimated Term Commencement Date:<br />Term: <br />Renewal Options: <br /><br />Rent Commencement Date: <br /><br />Fixed Minimum Rent/Rental Adjustments:<br /><br />Security Deposit: <br />Rent Payment Address:</>,
  address3: <>February 19, 2015<br />Osborne Shopping Center, LLC<br />DB REAL ESTATE ASSETS | LLC<br />Dunkin Donuts<br />n/a<br />Prototypical Dunkin Donuts<br />Approximatly 1,700 sf<br />Approximatly 1,763 sf<br />Seven (7) days per week during normal Dunkin Donuts hours<br /><br /><br />Ten (10) Years<br />Two (2) Five (5) Year Options<br />The date that is the earlier of: (a) ninety (90) calendar days following the latter of (i) Delivery Date and (ii) the date Tenant recieves all Necessary Permits; or (b) Tenant’s opening for business to the public.<br />$35.00/psf with escalations in accordance with Section 4.1<br />None<br />Osborne Shopping Center, LLC<br />PO Box 75609<br />Baltimore, MD 21275-5609</>,
  contactAddress: "Contact address",
  tenantAddress: "Tenant address",
  contantName: "Contant name",
  inputType1: "text",
  inputPlaceholder1: "Second address line",
  autoGenerated1: "Auto generated",
  text1: "–",
  notVerified1: "Not verified",
  tenant: "Tenant",
  inputType2: "text",
  inputPlaceholder2: "DB Real Estate Assets LLC",
  inputType3: "text",
  inputPlaceholder3: "Zip code",
  inputType4: "text",
  inputPlaceholder4: "9291 Proin Road",
  inputType5: "text",
  inputPlaceholder5: "801 Roosevelt Avenue",
  inputType6: "text",
  inputPlaceholder6: "(176) 805-4108",
  contactPhone: "Contact phone",
  inputType7: "text",
  inputPlaceholder7: "Suite F150",
  inputType8: "text",
  inputPlaceholder8: "Chaney Bennett",
  autoGenerated2: "Auto generated",
  text2: "–",
  notVerified2: "Not verified",
  rentStart: "Rent start",
  inputType9: "text",
  inputPlaceholder9: "February 19, 2015",
  autoGenerated3: "Auto generated",
  text3: "–",
  verified: "Verified",
  leasedSqFt: "Leased sq. ft.",
  inputType10: "text",
  inputPlaceholder10: "1,763 sf",
  expirationDate: "Expiration date",
  inputType11: "text",
  inputPlaceholder11: "October 10, 2030",
  inputType12: "text",
  inputPlaceholder12: "NJ",
  inputType13: "text",
  inputPlaceholder13: "MA",
  inputType14: "text",
  inputPlaceholder14: "11292",
  inputType15: "text",
  inputPlaceholder15: "Lake Charles",
  inputType16: "text",
  inputPlaceholder16: "Carteret",
  topNav2Props: topNav2Data,
  backButtonProps: backButton1Data,
  group450Props: group4502Data,
  tabsOpenProps: tabsOpenData,
  tabsUnselected1Props: tabsUnselected1Data,
  tabsUnselected2Props: tabsUnselected2Data,
  tabsUnselected3Props: tabsUnselected3Data,
  primaryButton2Props: primaryButton22Data,
};

const backButton2Data = {
  backToAllTenants: "BACK TO ALL PROPERTIES",
  className: "back-button-1",
};

const primaryButton23Data = {
  addANewProperty: "ADD A NEW TENANT",
  className: "primary-button-4",
};

const group4831Data = {
  x5ParagonDrive: "Chipotle",
  date: "May 30, 2028",
  className: "",
};

const tenantCard1Data = {
  id: 1,
  name: "UNIT 1039",
  squareFeet: "1,090 SF",
  createdAt: "1652111947913",
  updatedAt: "7:34pm on March 16, 2020",
  group483Props: group4831Data,
};

const group4832Data = {
  x5ParagonDrive: "Dunkin Donuts",
  date: "October 10, 2030",
  className: "group-483",
};

const tenantCard2Data = {
  id: 2,
  name: "UNIT 1032",
  squareFeet: "1,090 SF",
  createdAt: "1652111947913",
  updatedAt: "9:57am on March 15, 2020",
  className: "tenant-card-1-1",
  group483Props: group4832Data,
};

const group4833Data = {
  x5ParagonDrive: "Petco",
  date: "October 13, 2022",
  className: "group-483",
};

const tenantCard3Data = {
  id: 3,
  name: "UNIT 1050",
  squareFeet: "1,550 SF",
  createdAt: "1652111947913",
  updatedAt: "9:00am on March 15, 2020",
  className: "tenant-card-1-2",
  group483Props: group4833Data,
};

const group4834Data = {
  x5ParagonDrive: "Planet Fitness",
  date: "January 15, 2025",
  className: "group-483",
};

const tenantCard4Data = {
  id: 4,
  name: "UNIT 1049",
  squareFeet: "3,7500 SF",
  createdAt: "1652111947913",
  updatedAt: "10:10pm on March 16, 2020",
  className: "tenant-card",
  group483Props: group4834Data,
};

const group4835Data = {
  x5ParagonDrive: "Total Wine & More",
  date: "March 15, 2022",
  className: "group-483",
};

const tenantCard5Data = {
  id: 5,
  name: "UNIT 1044",
  squareFeet: "2,200 SF",
  createdAt: "1652111947913",
  updatedAt: "11:12am on March 14, 2020",
  className: "tenant-card-1",
  group483Props: group4835Data,
};

export const tenantViewData = {
  retail: "RETAIL",
  text4: "–",
  x200000Sf: "200,000 SF",
  echelonOneProperties: "Echelon One Properties",
  address1: <>801 Roosevelt Avenue<br />Carteret, NJ</>,
  address2: "5 tenants",
  sortBy: "Sort by:",
  alphabetical: "Alphabetical",
  lastUpdated: "Last updated",
  squareFootage: "Square footage",
  unitNumber: "Unit number",
  expirationDate: "Expiration date",
  backButtonProps: backButton2Data,
  primaryButton2Props: primaryButton23Data,
  data: [tenantCard1Data, tenantCard2Data, tenantCard3Data, tenantCard4Data, tenantCard5Data],
};

const primaryButton222Data = {
  addANewProperty: "UPLOAD",
};

const primaryButton3Data = {
  addANewProperty: "BEGIN ABSTRACT",
  className: "primary-button-2",
};

const createTenantData = {
  rectangle150: "",
  rectangle162: "",
  creatingANewTenant: "Creating a new tenant",
  step2AddTheLeas: "Step 3: Add the lease file and any amendments",
  step1NameTenant: "Step 1: Name Tenant",
  step3OrganizeTheFilesAsNeeded: "Step 3: Organize the files as needed",
  selectThePdfFilesToUpload: "Select the PDF files to upload",
  orDragAndDropThemHere: "Or drag and drop them here.",
  inputType: "text",
  inputPlaceholder: "Dunkin Donuts",
  orUploadThemFromAUrl: "Or, upload them from a URL",
  addTheUrl: "Add the URL",
  lease: "Lease",
  amendments: "Amendments",
  dragAndDropYourF: "Drag and drop your files from the left column to here. We recommend rearranging your amendments in chronological order.",
  primaryButton22Props: primaryButton222Data,
  primaryButtonProps: primaryButton3Data,
};