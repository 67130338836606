import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { DropResult } from 'react-beautiful-dnd';
import DraggableList from './DraggableList.jsx';
import DraggableListItem from './DraggableListItem.jsx';
import pick from '@cahil/utils/accessors/pick';
import { getItems, reorder } from './helpers.js';
import './AmendmentCards.css';
import 'scrollable-component';

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 0,
    height: 500,
    width: 500
  },
  root: {
    display: 'flow-root',
    flexWrap: 'wrap',
    // width: 500
  }
});

const AmendmentCards = (props) => {
  const amendments = props.unitAmendments
  console.log("Amendments in amendmentcards: ", amendments)
  const classes = useStyles();
  const [items, setItems] = React.useState(getItems(7));

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  return (
    <div className={classes.root} >
        <scrollable-component>
            <Paper className={classes.flexPaper } >
                <DraggableList items={items} onDragEnd={onDragEnd} />
            </Paper>
        </scrollable-component>
    </div>
  );
};

export default AmendmentCards;
