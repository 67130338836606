/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateProperty = /* GraphQL */ `
  subscription OnCreateProperty($owner: String) {
    onCreateProperty(owner: $owner) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const onUpdateProperty = /* GraphQL */ `
  subscription OnUpdateProperty($owner: String) {
    onUpdateProperty(owner: $owner) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const onDeleteProperty = /* GraphQL */ `
  subscription OnDeleteProperty($owner: String) {
    onDeleteProperty(owner: $owner) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const onCreateUnit = /* GraphQL */ `
  subscription OnCreateUnit($owner: String) {
    onCreateUnit(owner: $owner) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const onUpdateUnit = /* GraphQL */ `
  subscription OnUpdateUnit($owner: String) {
    onUpdateUnit(owner: $owner) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const onDeleteUnit = /* GraphQL */ `
  subscription OnDeleteUnit($owner: String) {
    onDeleteUnit(owner: $owner) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const onCreateLease = /* GraphQL */ `
  subscription OnCreateLease($owner: String) {
    onCreateLease(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const onUpdateLease = /* GraphQL */ `
  subscription OnUpdateLease($owner: String) {
    onUpdateLease(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const onDeleteLease = /* GraphQL */ `
  subscription OnDeleteLease($owner: String) {
    onDeleteLease(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const onCreateAmendment = /* GraphQL */ `
  subscription OnCreateAmendment($owner: String) {
    onCreateAmendment(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const onUpdateAmendment = /* GraphQL */ `
  subscription OnUpdateAmendment($owner: String) {
    onUpdateAmendment(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const onDeleteAmendment = /* GraphQL */ `
  subscription OnDeleteAmendment($owner: String) {
    onDeleteAmendment(owner: $owner) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const onCreateClause = /* GraphQL */ `
  subscription OnCreateClause($owner: String) {
    onCreateClause(owner: $owner) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
export const onUpdateClause = /* GraphQL */ `
  subscription OnUpdateClause($owner: String) {
    onUpdateClause(owner: $owner) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
export const onDeleteClause = /* GraphQL */ `
  subscription OnDeleteClause($owner: String) {
    onDeleteClause(owner: $owner) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
