
import React from "react";
import styles from "./styles.module.scss";

const StepProgress = ({step}) => {
  return (
    <div className={styles.container}>
      <div className={styles.shapeContainer}>
        <div className={step === 0 ? styles.outlinedCircle : styles.filledCircle} />
        <div className={step === 0 ? styles.emptyLine : styles.outlinedLine} />
        <div className={step === 0 ? styles.emptyCircle : step === 1 ? styles.outlinedCircle : styles.filledCircle} />
        <div className={step === 2 ? styles.outlinedLine : styles.emptyLine} />
        <div className={step === 2 ? styles.outlinedCircle : styles.emptyCircle} />
      </div>
      <div className={styles.labelContainer}>
        <p>Tenant Info</p>
        <p>Lease</p>
        <p>Amemdments</p>
      </div>
    </div>
  )
};

export default StepProgress;
