import React, {useEffect, useMemo, useRef, useState} from "react";
import {Stack, Typography, IconButton, Button} from "@mui/material";
import {CSVLink} from 'react-csv';
import {useDrop} from 'react-dnd'
import TextareaAutosize from 'react-textarea-autosize';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import classNames from 'classnames';
import Avatar from '@mui/material/Avatar';
import DownloadIcon from '@mui/icons-material/Download';
// import MenuIcon from '@mui/icons-material/Menu';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import {SearchBar} from "components/SearchBar";
import {StyledTabs, StyledTab} from "components/Tabs";
import TabPanel from "components/TabPanel";

import styles from "./styles.module.scss";
import {convertFromISODate} from "utils/date-util";

const AbstractSidebar = ({
  clauses,
  propertyUnit,
  message,
  handleMessageChange,
  handleSaveChange,
  handleAddToAbstract,
  handleClauseDialog,
  handleJumpHighlight,
}) => {
  const csvLink = useRef();
  const [{canDrop, isOver}, drop] = useDrop(() => ({
    accept: 'Clause',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  }))
  const isActive = canDrop && isOver;
  const [expand, setExpand] = useState(false);
  const [abstractKeyword, setAbstractKeyword] = useState('');
  const [abstractTabIndex, setAbstractTabIndex] = React.useState(0);
  // const [toggleButton, setToggleButton] = useState(0);
  const [filteredClauses, setFilteredClauses] = useState(clauses);
  const basicInfo = useMemo(() => filteredClauses.filter(clause => clause.type === 'Basic Info'), [filteredClauses]);
  const keyTerms = useMemo(() => filteredClauses.filter(clause => clause.type === 'Key Terms'), [filteredClauses]);
  const notes = useMemo(() => filteredClauses.filter(clause => clause.type === 'Notes'), [filteredClauses]);
  const other = useMemo(() => filteredClauses.filter(clause => clause.type === 'Other'), [filteredClauses]);
  const highlightedClauses = useMemo(() => filteredClauses.filter(clause => clause.note !== undefined), [filteredClauses]);

  const downloads = useMemo(() => {
    let downloadArrays = [];
    highlightedClauses.forEach(element => {
      downloadArrays.push({tenant: propertyUnit.name, name: element.name, summary: element.note.content});
    });
    return downloadArrays;
  }, [highlightedClauses])

  useEffect(() => {
    if (abstractKeyword !== '') {
      const updatedClauses = clauses.filter(item => {
        const searchText = abstractKeyword.toLowerCase();
        const clauseName = item.name?.toLowerCase();
        const message = item.note?.content?.toLowerCase();
        return clauseName?.includes(searchText) || message?.includes(searchText);
      });
      setFilteredClauses(updatedClauses);
    } else {
      setFilteredClauses(clauses);
    }
  }, [abstractKeyword, clauses]);

  const handleChangeAbstractKeyword = event => {
    setAbstractKeyword(event.target.value);
  }

  const handleChangeAbstractTab = (event, newValue) => {
    setAbstractTabIndex(newValue);
  };

  // const handleToggleButton = (index) => {
  //   setToggleButton(index);
  // };

  const handlePrintClauses = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFontSize(13);
    pdf.text(20, 20, propertyUnit.property.name);
    pdf.text(20, 30, `${propertyUnit.property.category} - ${propertyUnit.property.totalSqFt} SF`);
    pdf.text(20, 40, propertyUnit.property.street);
    pdf.text(20, 50, propertyUnit.name);
    pdf.setFontSize(13);
    const columns = [
      "Name",
      "Summary",
    ];
    var rows = [];
    for (let i = 0; i < downloads.length; i++) {
      var temp = [
        downloads[i].name,
        downloads[i].summary,
      ];
      rows.push(temp);
    }
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247]
      },
      alternateRowStyles: {
        fillColor: [212, 212, 212],
        textColor: [0, 0, 0],
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      tableLineColor: [0, 0, 0]
    });
    pdf.save('pdf');
  };

  const handleDownloadClauses = () => {
    csvLink.current.link.click();
    handlePrintClauses();
  };

  const renderClausesItem = (tabIndex, clause, index) => {
    return (
      clause.state === 1 ? (
        <Stack key={`stack-${index}`} marginTop="16px" padding="16px" sx={{background: "#2666E6;", border: "1.5px solid rgba(255, 255, 255, 0.1);", borderRadius: "8px;"}}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{fontSize: "18px", fontWeight: 600, color: "white", textTransform: "uppercase"}}>{clause.name}</Typography>
            <IconButton sx={{width: "24px", height: "24px"}} onClick={() => handleClauseDialog(clause.id, 0)}>
              <CloseIcon sx={{color:"rgba(255,255,255,0.7);"}}/>
            </IconButton>
          </Stack>
          <Typography sx={{color: "rgba(255, 255, 255, 0.5);"}}>SUMMARY</Typography>
          <TextareaAutosize
            ref={drop}
            minRows={5}
            maxRows={10}
            className={classNames(styles.textarea, isActive &&  styles.activeTextArea)}
            onChange={handleMessageChange}
            value={message}
          />
          <Button onClick={() => handleSaveChange(clause.id)} variant="contained" sx={{marginTop: "12px", textTransform: "none", background: "white", color: "#2666E6", "&:hover": {background: "#cecece"}}}>Save Changes</Button>
        </Stack>
       ) :  clause.note !== undefined ? (
        <Stack
          key={`stack-${index}`}
          marginTop="16px"
          padding="16px"
          sx={{position: "relative", cursor: "pointer", border: "1.5px solid rgba(255, 255, 255, 0.1);", borderRadius: "8px"}}
          onClick={() => handleJumpHighlight(clause.id)}>
          <Stack>
            <Typography sx={{fontFamily: "Roboto", fontSize: "18px", fontWeight: 600, color: "white", textTransform: "uppercase"}}>{clause.name}</Typography>
            <Typography sx={{fontFamily: "Roboto", fontSize: "14px", fontWeight: 400, color: "white"}}>{clause.note.content}</Typography>
            {clause.autogenerated ? (
              <>
                <Stack padding="8px" marginTop="16px" marginBottom="16px" direction="row" alignItems="center" spacing={1} sx={{backgroundColor: "rgba(255, 255, 255, 0.1);", borderRadius: "4px"}}>
                  <Avatar sx={{width: "28px", height: "28px", background: "#2666E6"}}>
                    <QuestionMarkIcon sx={{color:"white"}}/>
                  </Avatar>
                  <Typography sx={{fontFamily: "Roboto", fontSize: "12px", fontWeight: 400, color: "white"}}>
                    We spotted some clauses in your documents. If they’re correct, let’s add them to your terms!
                  </Typography>
                </Stack>
                <Button onClick={() => handleAddToAbstract(clause.id)} variant="contained" sx={{textTransform: "none", background: "white", color: "#2666E6", "&:hover": {background: "#cecece"}}}>Add to Abstract</Button>
              </>
            ) : (
              <Typography marginTop="10px" sx={{fontFamily: "Roboto", fontSize: "12px", fontWeight: 400, color: "rgba(255, 255, 255, 0.5);"}}>Last Modified: {convertFromISODate(clause.note.lastModifiedAt)}</Typography>
            )}
          </Stack>
          <IconButton
            sx={{position: 'absolute', right: 8, top: 8}}
            onClick={(e) => {
            e.stopPropagation();
            handleClauseDialog(clause.id, 1);
          }}>
            <EditIcon sx={{color:"rgba(255,255,255,0.7);"}}/>
          </IconButton>
        </Stack>
      ) : (
        <Stack key={`stack-${index}`} marginTop="16px" padding="16px" sx={{border: "1.5px solid rgba(255, 255, 255, 0.1);", borderRadius: "8px"}} spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{fontFamily: "Roboto", fontSize: "18px", fontWeight: 600, color: "white", textTransform: "uppercase"}}>{clause.name}</Typography>
            </Stack>
          </Stack>
          <Button onClick={() => handleClauseDialog(clause.id, 1)} variant="contained" sx={{textTransform: "none", background: "white", color: "#2666E6", "&:hover": {background: "#cecece"}}}>Build Clause</Button>
        </Stack>
       )
    )
  }

  return (
    <div className={classNames(styles.container, expand && styles.expandMode)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{fontFamily: "Roboto", fontSize: "24px", fontWeight: 700, color: "white"}}>Abstract</Typography>
        <Stack direction="row" spacing={1}>
          <IconButton sx={{width: "35px", height: "35px", background: "#2666E6", color: "white"}} onClick={handleDownloadClauses}>
            <DownloadIcon />
          </IconButton>
          <CSVLink
            className="hidden"
            data={downloads}
            filename="highlighted-clauses.csv"
            ref={csvLink}
            target="_blank"/>
        </Stack>
      </Stack>
      <SearchBar className={styles.searchBar} darkmode="true" placeholder="Search abstract" value={abstractKeyword} onChange={handleChangeAbstractKeyword}/>
      <StyledTabs value={abstractTabIndex} onChange={handleChangeAbstractTab}>
        <StyledTab label="Basic Info" />
        <StyledTab label="Key Terms" />
        <StyledTab label="Notes" />
        <StyledTab label="Other" />
      </StyledTabs>
      <TabPanel maxHeight="calc(100vh - 290px);" value={abstractTabIndex} index={0}>
        {/* <div className={styles.toggleContainer}>
          <div className={classNames(styles.leftSelected, toggleButton === 1 && styles.leftDeselected)} onClick={() => handleToggleButton(0)}>
            To Review (2)
          </div>
          <div className={classNames(styles.rightSelected, toggleButton === 0 && styles.rightDeselected)} onClick={() => handleToggleButton(1)}>
            Approved
          </div>
        </div> */}
        {/* <Stack marginTop="16px" padding="16px" sx={{border: "1.5px solid rgba(255, 255, 255, 0.1);", borderRadius: "8px"}} spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography sx={{fontFamily: "Roboto", fontSize: "14px", fontWeight: 400, color: "white"}}>{clause}</Typography>
              <Typography sx={{fontFamily: "Roboto", fontSize: "16px", fontWeight: 400, color: "white"}}>Dunkin’ Donuts</Typography>
            </Stack>
            <IconButton sx={{height: "40px"}}>
              <EditIcon sx={{color:"rgba(255,255,255,0.7);"}}/>
            </IconButton>
          </Stack>
          <Button variant="contained" sx={{textTransform: "none", background: "white", color: "#2666E6", "&:hover": {background: "#cecece"}}}>Add to Abstract</Button>
        </Stack> */}
        {
          basicInfo.map((clause, index) => {
            return renderClausesItem(0, clause, index)
          })
        }
      </TabPanel>
      <TabPanel maxHeight="calc(100vh - 290px);" value={abstractTabIndex} index={1}>
        {
          keyTerms.map((clause, index) => {
            return renderClausesItem(1, clause, index)
          })
        }
      </TabPanel>
      <TabPanel maxHeight="calc(100vh - 290px);" value={abstractTabIndex} index={2}>
        {
          notes.map((clause, index) => {
            return renderClausesItem(2, clause, index)
          })
        }
      </TabPanel>
      <TabPanel maxHeight="calc(100vh - 290px);" value={abstractTabIndex} index={3}>
        {
          other.map((clause, index) => {
            return renderClausesItem(3, clause, index)
          })
        }
      </TabPanel>
      <div className={styles.expand}>
        <IconButton sx={{width: "35px", height: "35px"}} onClick={() => setExpand(expand => !expand)}>
          <img src='/img/icon-expand.svg' alt="expand"/>
        </IconButton>
      </div>
    </div>
  )
};

export default AbstractSidebar;

