import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {toast} from 'react-toastify';
import API, { graphqlOperation } from "@aws-amplify/api";
import {updateAmendment} from "graphql/mutations";
import InputWithBorder from "components/InputWithBorder";
import styles from "./styles.module.scss";

const PDFRenameView = ({
  setOpenRename,
  item,
  onChanged,
}) => {
  const [name, setName] = useState(item.name);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (name !== '') {
      const input = {
        id: item.id,
        name: name,
      };
      setLoading(true);
      const changedItem = await API.graphql(graphqlOperation(updateAmendment, { input }));      
      setLoading(false);
      onChanged(changedItem.data.updateAmendment);
    } else {
      toast.warn("Please enter pdf name", {position: "top-right", autoClose: 2000, hideProgressBar: true});
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  return (
    <div className={styles.popupContainer} onClick={() => setOpenRename(false)}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <InputWithBorder
          value={name}
          onChange={handleChangeName}
        />
        <LoadingButton
          loading={loading}
          onClick={handleSave}
          variant="contained"
          sx={{marginLeft: "8px", borderRadius: "8px", textTransform: "none"}}>
          Save
        </LoadingButton>
      </div>
    </div>
  )
};

export default PDFRenameView;
