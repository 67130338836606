import React, {useState} from 'react';
import {Stack} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import {Auth} from 'aws-amplify';
import InputWithBorder from "components/InputWithBorder";
import {toast} from 'react-toastify';
import styles from './styles.module.scss';

const SignIn = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorUserName, setErrorUserName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeUserName = (event) => {
    const value = event.target.value;
    setUserName(value);
    if (value !== '') {
      setErrorUserName(false);
    }
  };

  const handleChangePassword = (event) => {
    const value = event.target.value;
    setPassword(value);
    if (value !== '') {
      setErrorPassword(false);
    }
  };

  const handleSignIn = async () => {
    if (username === '') {
      setErrorUserName(true);
      return;
    } else if (password === '') {
      setErrorPassword(true);
      return;
    }
    try {
      setLoading(true);
      await Auth.signIn(username, password);
      window.location.reload(false);
    } catch (error) {
      console.log(error?.message);
      toast.error(error?.message, {position: "top-right",autoClose: 2000, hideProgressBar: true});
    }
    setLoading(false);
  };

  return (
    <Stack direction="column">
      <h3 className={styles.title}>Welcome Back!</h3>
      <p className={styles.subtitle}>Please enter your username and password to login.</p>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>USERNAME</p>
        <InputWithBorder
          className={styles.input}
          placeholder="johnsmith1"
          value={username}
          error={errorUserName}
          onChange={handleChangeUserName}
        />
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>PASSWORD</p>
        <InputWithBorder
          className={styles.input}
          placeholder="password"
          type="password"
          value={password}
          error={errorPassword}
          onChange={handleChangePassword}
        />
      </div>
      <LoadingButton
        loading={loading}
        sx={{marginTop: "30px", backgroundColor: "#2666E6"}}
        variant="contained"
        onClick={handleSignIn}>
        Sign In
      </LoadingButton>
    </Stack>
  );
};

export default SignIn;
