import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  marginTop: "10px",
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#196FE4',
  },
  // '& .MuiTabs-indicatorSpan': {
  //   maxWidth: 40,
  //   width: '100%',
  //   backgroundColor: '#196FE4',
  // },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme, darkmode }) => ({
    minWidth: "80px",
    textTransform: 'none',
    fontWeight: 400,
    fontSize: "15px",
    color: darkmode === "true" ? "#232839": "#C4C4C4",
    '&.Mui-selected': {
      color: darkmode === "true" ? "#2666E6" : "#fff",
    },
  }),
);