// src/App.js

import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "./pages/Auth";
import "./styles.css";

import awsExports from "./aws-exports";
import Routes from "./routes";
import { Worker } from '@react-pdf-viewer/core';

Amplify.configure(awsExports);

export default function App() {

  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  if (authStatus === 'authenticated') {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
        <Routes />
        <ToastContainer />
      </Worker>
    );
  }

  return (
    <div>
      <Auth />
      <ToastContainer />
    </div>
  );
}
