import React, {useCallback, useEffect, useState} from "react";
import {Box, Container, Stack, Typography, Button, Grid} from "@mui/material";
import orderBy from "lodash/orderBy";
import API, {graphqlOperation} from "@aws-amplify/api";
import {Auth} from "aws-amplify";
import {updateProperty} from "../../graphql/mutations";

import {listProperties} from "graphql/queries";
import {onCreateProperty} from "graphql/subscriptions";
import NavLayout from "components/NavLayout";
import {SearchBar} from "components/SearchBar";
import PropertyCard from "components/PropertyCard";
import CreateProperty from "components/CreateProperty";
import EditProperty from "components/EditProperty";

const Home = () => {
  const [createPropertyVisible, setCreatePropertyVisible] = useState(false);
  const [editPropertyVisible, setEditPropertyVisible] = useState(false);
  const [editingProperty, setEditingProperty] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [propertyCategory, setPropertyCategory] = useState(0);
  const [order, setOrder] = useState('asc');
  const [keyword, setKeyword] = useState('');

  const sortList = [
    {
      id: 0,
      title: 'Alphabetical',
      value: 'name',
    },
    {
      id: 1,
      title: 'Last Updated',
      value: 'updatedAt',
    },
    {
      id: 2,
      title: 'Square Footage',
      value: 'totalSqFt',
    },
    {
      id: 3,
      title: 'Active',
      value: 'active',
    },
  ];

  useEffect(() => {
    refreshProperty();
    Auth.currentAuthenticatedUser().then(user => {
      // Listen to new properties being added
      const subscription = API.graphql(
        graphqlOperation(onCreateProperty, { owner: user.username })
      ).subscribe(newProperty => {
        const propertyRecord = newProperty.value.data.onCreateProperty;
        setProperties(albs => [...albs, propertyRecord]);
      });
      return () => subscription.unsubscribe();
    });
  }, []);

  useEffect(() => {
    if (keyword === '') {
      setFilteredProperties(properties);
    } else {
      const filteredList = properties.filter(item => item.name?.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredProperties(filteredList);
    }
  }, [keyword, properties]);

  const refreshProperty = () => {
    setIsLoading(true);
    // Get initial properties list
    API.graphql(graphqlOperation(listProperties)).then(albs => {
      setProperties(albs.data.listProperties.items);
      setIsLoading(false);
    });
  };

  const handleCategoryChange = (index) => {
    setPropertyCategory(index);
    if (propertyCategory === index) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrder('asc');
    }
  };

  const handleChangeKeyword = event => {
    setKeyword(event.target.value);
  };

  const onPropertyCreated = () => {
    console.log("New property created");
    setCreatePropertyVisible(false);
  };

  const onPropertyUpdated = () => {
    setEditPropertyVisible(false);
    refreshProperty();
  };

  const onPropertyActiveUpdated = (property, active) => {
    const input = {
      id: property.id,
      active: active,
    };
    API.graphql(graphqlOperation(updateProperty, { input })).then(result => {
      const updatedProperty = result.data.updateProperty;
      const updatedProperties = [...properties];
      const index = updatedProperties.findIndex(item => item.id === updatedProperty.id);
      updatedProperties[index] = updatedProperty;
      setProperties(updatedProperties);  
    });
  };

  const onEditProperty = useCallback((property) => {
    setEditingProperty(property);
    setEditPropertyVisible(true);
  }, []);

  return (
    <NavLayout>
      <Box padding={{xs: "20px 10px", md: "30px"}}>
        <Container maxWidth="lg">
          <Stack spacing={2}>
            <Stack direction={{xs: "column", md: "row"}} justifyContent="space-between">
              <Typography sx={{fontSize: "27px", fontWeight: 700, color: "black"}}>
                Properties ({properties.length})
              </Typography>
              <Stack direction={{xs: "column", md: "row"}} spacing={2} marginTop={{xs: 1, md: 0}}>
                <SearchBar placeholder="Search property" value={keyword} onChange={handleChangeKeyword} />
                <Button 
                  variant="contained"
                  onClick={() => setCreatePropertyVisible(true)}
                  sx={{color: "white", fontSize: "14px", fontWeight: 500, textTransform: "none", borderRadius: "8px"}}>
                  Add New Property
                </Button>
              </Stack>
            </Stack>
            <Box>
              <Typography sx={{fontSize: "12px"}}>Sort by:</Typography>
              <Stack marginTop="8px" spacing={{xs: 1, sm: 2}} direction="row">
                {
                  sortList.map(sortItem => (
                    <Button
                      key={sortItem.id}
                      size="small"
                      variant={propertyCategory === sortItem.id ? "contained" : "outlined"}
                      sx={{textTransform: "none", borderRadius: {xs: "12px", sm: "32px"}, fontSize: {xs: "11px", sm: "14px"}}}
                      onClick={() => handleCategoryChange(sortItem.id)}>
                      {sortItem.title}
                    </Button>
                  ))
                }
              </Stack>
              <Grid container spacing={2} marginTop="12px">
                {orderBy(filteredProperties.filter(item => sortList[propertyCategory].value !== "active" ? item.active !== false : item) , [sortList[propertyCategory].value], [order]).map(property => (
                  <Grid item key={property.id}>
                    <PropertyCard
                      category={property.category}
                      name={property.name}
                      totalSqFt={property.totalSqFt}
                      updatedAt={property.updatedAt}
                      street={property.street}
                      createdAt={property.createdAt}
                      propertyId={property.id}
                      property={property}
                      onPropertyActiveUpdated={onPropertyActiveUpdated}
                      onEditProperty={onEditProperty}
                    />
                  </Grid>
                ))}
                {sortList[propertyCategory].value !== "active" && filteredProperties.filter(item => item.active === false).map(property => (
                  <Grid item key={property.id}>
                    <PropertyCard
                      category={property.category}
                      name={property.name}
                      totalSqFt={property.totalSqFt}
                      updatedAt={property.updatedAt}
                      street={property.street}
                      createdAt={property.createdAt}
                      propertyId={property.id}
                      property={property}
                      onPropertyActiveUpdated={onPropertyActiveUpdated}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Container>
        {createPropertyVisible && (
          <CreateProperty
            setOpenPopup={setCreatePropertyVisible}
            onPropertyCreated={onPropertyCreated}
          />
        )}
        {editPropertyVisible && (
          <EditProperty
            setOpenPopup={setEditPropertyVisible}
            onPropertyUpdated={onPropertyUpdated}
            property={editingProperty}
          />
        )}
      </Box>
    </NavLayout>
  )
};

export default Home;
