import React, {useState} from "react";
import {Stack, Box, Typography, IconButton} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import API, { graphqlOperation } from "@aws-amplify/api";
import {updateUnit} from "graphql/mutations";

import InputWithBorder from "components/InputWithBorder";
import styles from "./styles.module.scss";
import {getNumber} from "../../utils/number-util";

const EditTenant = ({
  setOpenPopup,
  unit,
  onTenantUpdated,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorTenantName, setErrorTenantName] = useState(false);
  const [errorSquareFeet, setErrorSquareFeet] = useState(false);
  const [tenantName, setTenantName] = useState(unit.name);
  const [squareFeet, setSquareFeet] = useState(unit.squareFeet.toLocaleString());

  const handleChangeTenantName = (event) => {
    const value = event.target.value;
    setTenantName(value);
    if (value !== '') {
      setErrorTenantName(false);
    }
  };

  const handleChangeSquareFeet = (event) => {
    const value = event.target.value;
    const number = getNumber(value);
    if (number === 0) {
      setSquareFeet('');
    } else {
      setSquareFeet(number.toLocaleString());
    }
    if (value !== '') {
      setErrorSquareFeet(false);
    }
  };

  const handleUpdateTenant = () => {
    if (validateFields()) {
      _updateTenant();
    }
  };

  const _updateTenant = async () => {
    const input = {
      id: unit.id,
      name: tenantName,
      squareFeet: getNumber(squareFeet),
    };
    setLoading(true);
    await API.graphql(graphqlOperation(updateUnit, { input }));
    setLoading(false);
    onTenantUpdated();
  };
  
  const validateFields = () => {
    if (tenantName.trim() === '') {
      setErrorTenantName(true);
      return false;
    }
    if (squareFeet.trim() === '') {
      setErrorSquareFeet(true);
      return false;
    }
    return true;
  }

  return (
    <div className={styles.popupContainer}>
      <div className={styles.container}>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{fontSize: "27px", fontWeight: 700, color: "black"}}>
            Edit Tenant
          </Typography>
          <IconButton
            sx={{color: "#2666E6"}}
            onClick={() => setOpenPopup(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography sx={{fontSize: "16px", color: "black"}}>
          Please fill out all the fields to edit tenant.
        </Typography>
        <Box marginTop="30px" marginBottom="36px" width="100%" height="1px" backgroundColor="#C4C4C4" />
        <div className={styles.inputWrapper}>
          <p className={styles.label}>TENANT NAME</p>
          <InputWithBorder
            className={styles.input}
            value={tenantName}
            error={errorTenantName}
            onChange={handleChangeTenantName}
          />
        </div>
        <div className={styles.inputWrapper}>
          <p className={styles.label}>UNIT SIZE (IN SQUARE FEET)</p>
          <InputWithBorder
            className={styles.input}
            value={squareFeet}
            error={errorSquareFeet}
            onChange={handleChangeSquareFeet}
          />
        </div>
        <Stack marginTop="40px" direction="row" justifyContent="center">
          <Stack spacing={1}>
            <LoadingButton
              loading={loading}
              onClick={handleUpdateTenant}
              variant="contained"
              sx={{borderRadius: "8px", textTransform: "none"}}>
              Update Tenant
            </LoadingButton>
          </Stack>
        </Stack>
      </div>
    </div>
  )
};

export default EditTenant;
