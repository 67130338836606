import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {IconButton, Switch, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { convertFromISODate } from "../../utils/date-util";
import styles from "./styles.module.scss";
import classNames from 'classnames';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#2666E6',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function PropertyCard(props) {
  const { category, totalSqFt, name, street, updatedAt, propertyId, property, onPropertyActiveUpdated, onEditProperty} = props;
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(property?.active !== false);
  }, [property]);

  const handleChange = event => {
    const state = event.target.checked;
    setChecked(state);
    onPropertyActiveUpdated(property, state);
  };

  const handleEditProperty = e => {
    e.preventDefault();
    onEditProperty(property);
  };

  return (
    // pass tennant view the property id for future lookups
    
    <Link to={`/tenantview/${propertyId}`}>
      <div className={classNames(styles.container, !checked && styles.disabledState)}>
        <IconButton
          sx={{position: 'absolute', top: 10, right: 10}}
          onClick={handleEditProperty}>
          <EditIcon />
        </IconButton>
        <div className={styles.header}>
          <span className={styles.retail}>
            {category}  •   {totalSqFt.toLocaleString()} SF
          </span>
          <h3>{name}</h3>
        </div>
        <div className={styles.footer}>
          <p className={styles.street}>{street}</p>
          <p className={styles.lastModifiedDate}>
            Last Modified: {convertFromISODate(updatedAt)}
          </p>
          <FormControlLabel
            onClick={(e) => e.stopPropagation()}
            checked={checked}
            onChange={handleChange}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={<Typography sx={{fontSize: "12px"}}>{checked ? "Active" : "Inactive"}</Typography>}
          />
        </div>
      </div>
    </Link>
  );
}

export default PropertyCard;
