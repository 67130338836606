import React from 'react';
import {RenderZoomInProps, RenderZoomOutProps} from '@react-pdf-viewer/zoom';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {IconButton} from '@mui/material';

import styles from './styles.module.scss';

export const ZoomInOut = React.memo(({className, zoomPluginInstance}) => {
  const {ZoomIn, ZoomOut} = zoomPluginInstance;
  return (
    <div className={styles.zoomContainer}>
      <ZoomOut>
        {(props: RenderZoomOutProps) => (
            <IconButton
              sx={{color: "#2666E6", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} 
              onClick={props.onClick}
            >
              <RemoveCircleIcon />
            </IconButton>
        )}
      </ZoomOut>
      {/* <CurrentScale>
          {(props: RenderCurrentScaleProps) => <>{`${Math.round(props.scale * 100)}%`}</>}
      </CurrentScale> */}
      <ZoomIn>
        {(props: RenderZoomInProps) => (
            <IconButton
              sx={{color: "#2666E6", width: {xs: "24px", md: "40px"}, height: {xs: "24px", md: "40px"}}} 
              onClick={props.onClick}
            >
              <AddCircleIcon />
            </IconButton>
        )}
      </ZoomIn>
    </div>
  )
});