import React from "react";
import "./PrimaryButton3.css";
import { Link } from "react-router-dom";

function PrimaryButton3(props) {
  const { addANewProperty, className } = props;

  return (
    <div className={`primary-button ${className || ""}`}>
      <div className="overlap-group-4">
        <Link to="/leaseview">
          <img className="rectangle-88" src="/img/rectangle-88-1@2x.svg" />
          <p className="add-a-new-property lato-extra-bold-white-12px">{addANewProperty}</p>
        </Link>        
      </div>
    </div>
  );
}

export default PrimaryButton3;
