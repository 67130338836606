import {AmplifyProvider, Authenticator} from "@aws-amplify/ui-react";
import {React, StrictMode} from "react";
import ReactDOM from "react-dom";
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import "./globals.css"
import "./styleguide.css"
import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </ThemeProvider>
      </Authenticator.Provider>
    </AmplifyProvider>
  </StrictMode>,
  rootElement
);
