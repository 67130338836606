
import React, {useState} from "react";
import InputWithBorder from "components/InputWithBorder";
import styles from "./styles.module.scss";

const StepFirst = ({tenantName, squareFeet, errorTenantName, errorSquareFeet, handleChangeTenantName, handleChangeSquareFeet}) => {

  return (
    <div className={styles.container}>
      <h3>Tenant Information</h3>
      <p className={styles.description}>This information will appear for your own reference, but will not modify your documentation.</p>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>TENANT NAME</p>
        <InputWithBorder
          className={styles.input}
          value={tenantName}
          error={errorTenantName}
          onChange={handleChangeTenantName}
        />
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>UNIT SIZE (IN SQUARE FEET)</p>
        <InputWithBorder
          className={styles.input}
          value={squareFeet}
          error={errorSquareFeet}
          onChange={handleChangeSquareFeet}
        />
      </div>
    </div>
  )
};

export default StepFirst;
