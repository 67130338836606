
import moment from 'moment';

export const convertFromISODate = (dateString) => {
  const date = moment(dateString).format('MMMM D, YYYY H:mm a');
  if(date === 'Invalid date') return '';
  return date;
};

export const convertFrom1970sDate = (milliseconds) => {
  const date = moment(new Date(milliseconds)).format('MMMM D, YYYY H:mm a');
  return date;
};