import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import NextButton from "@mui/icons-material/KeyboardArrowDown";
import PrevButton from "@mui/icons-material/KeyboardArrowUp";

import styles from "./styles.module.scss";
import { IconButton } from '@mui/material';
import {Spinner} from '@react-pdf-viewer/core';

const NotSearchedYet = 0;
const Searching = 1;
const FoundResults = 2;

export const PDFSearchBar = React.memo(({className, darkmode, tabIndex, onMatchChanged, searchPluginInstance, ...props}) => {
  const [searchStatus, setSearchStatus] = useState(NotSearchedYet);
  const [matches, setMatches] = useState([]);
  const {Search} = searchPluginInstance;

  useEffect(() => {
    setMatches([]);
  }, [tabIndex]);

  const handleMatchChanged = (result) => {
    setMatches(result);
    onMatchChanged(result);
  };

  return (
    <Search>
      {(renderSearchProps) => {
        const {clearKeyword, keyword, setKeyword, currentMatch, jumpToNextMatch, jumpToPreviousMatch, search } = renderSearchProps;

        const handleChange = (e) => {
          const value = e.target.value;
          setKeyword(value);
          if (value === '') {
            handleMatchChanged([]);
            clearKeyword();
          }
        };

        const handleSearchKeyDown = (e) => {
          if (e.key === 'Enter') {
            if (keyword) {
              setSearchStatus(Searching);
              search().then((matches) => {
                setSearchStatus(FoundResults);
                handleMatchChanged(matches);
              });
            }
          }
        };
        return (
          <div className={classNames(styles.searchContainer, className)}>
            <div className={styles.searchInputContainer}>
              <SearchIcon style={{ color: darkmode === "true" ? "white" : "#196FE4" }}/>
              <Input
                placeholder={props.placeholder}
                disableUnderline
                onChange={handleChange}
                value={keyword}
                onKeyDown={handleSearchKeyDown}
                sx={{

                  color: darkmode === "true" ? "#C4C4C4" : "#747474",
                  marginLeft: 1,
                  flex: 1,
                }}
              />
            </div>
            {searchStatus === Searching ? (
              <div className={styles.resultContainer}>
                <Spinner size="24px"/>
              </div>
            ) : keyword && matches.length > 0 && (
              <div className={styles.resultContainer}>
                <p className={styles.resultText}>{currentMatch} / {matches.length}</p>
                <IconButton sx={{width: "24px", height: "24px"}} onClick={jumpToPreviousMatch}>
                  <PrevButton />
                </IconButton>
                <IconButton sx={{width: "24px", height: "24px"}} onClick={jumpToNextMatch}>
                  <NextButton />
                </IconButton>
              </div>
            )}
          </div>
        );
      }}
    </Search>
  )
});