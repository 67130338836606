import React from "react";
import TopNav from "../TopNav";
import styles from "./styles.module.scss";

const NavLayout = ({children}) => {
  return (
    <div className={styles.container}>
      <TopNav />
      {children}
    </div>
  )
};

export default NavLayout;