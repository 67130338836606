export const getNumber = (_str) => {
  var arr = _str.split('');
  var out = [];
  for(var cnt=0;cnt<arr.length;cnt++){
    if(isNaN(arr[cnt]) === false){
      out.push(arr[cnt]);
    }
  }
  return Number(out.join(''));
}
