/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zipcode
      createdAt
      totalSqFt
      category
      units {
        items {
          id
          name
          squareFeet
          createdAt
          active
          updatedAt
          propertyUnitsId
          owner
        }
        nextToken
      }
      active
      updatedAt
      owner
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      name
      squareFeet
      property {
        id
        name
        street
        city
        state
        zipcode
        createdAt
        totalSqFt
        category
        units {
          nextToken
        }
        active
        updatedAt
        owner
      }
      lease {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitLeaseId
          owner
        }
        nextToken
      }
      amendments {
        items {
          id
          bucket
          textractBucket
          s3Path
          name
          status
          createdAt
          updatedAt
          unitAmendmentsId
          owner
        }
        nextToken
      }
      clause {
        items {
          id
          clause
          summary
          highlights
          createdAt
          confidence
          updatedAt
          unitClauseId
          owner
        }
        nextToken
      }
      createdAt
      active
      updatedAt
      propertyUnitsId
      owner
    }
  }
`;
export const createLease = /* GraphQL */ `
  mutation CreateLease(
    $input: CreateLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    createLease(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const updateLease = /* GraphQL */ `
  mutation UpdateLease(
    $input: UpdateLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    updateLease(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const deleteLease = /* GraphQL */ `
  mutation DeleteLease(
    $input: DeleteLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    deleteLease(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitLeaseId
      owner
    }
  }
`;
export const createAmendment = /* GraphQL */ `
  mutation CreateAmendment(
    $input: CreateAmendmentInput!
    $condition: ModelAmendmentConditionInput
  ) {
    createAmendment(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const updateAmendment = /* GraphQL */ `
  mutation UpdateAmendment(
    $input: UpdateAmendmentInput!
    $condition: ModelAmendmentConditionInput
  ) {
    updateAmendment(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const deleteAmendment = /* GraphQL */ `
  mutation DeleteAmendment(
    $input: DeleteAmendmentInput!
    $condition: ModelAmendmentConditionInput
  ) {
    deleteAmendment(input: $input, condition: $condition) {
      id
      bucket
      textractBucket
      s3Path
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      name
      status
      createdAt
      updatedAt
      unitAmendmentsId
      owner
    }
  }
`;
export const createClause = /* GraphQL */ `
  mutation CreateClause(
    $input: CreateClauseInput!
    $condition: ModelClauseConditionInput
  ) {
    createClause(input: $input, condition: $condition) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
export const updateClause = /* GraphQL */ `
  mutation UpdateClause(
    $input: UpdateClauseInput!
    $condition: ModelClauseConditionInput
  ) {
    updateClause(input: $input, condition: $condition) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
export const deleteClause = /* GraphQL */ `
  mutation DeleteClause(
    $input: DeleteClauseInput!
    $condition: ModelClauseConditionInput
  ) {
    deleteClause(input: $input, condition: $condition) {
      id
      clause
      summary
      highlights
      unit {
        id
        name
        squareFeet
        property {
          id
          name
          street
          city
          state
          zipcode
          createdAt
          totalSqFt
          category
          active
          updatedAt
          owner
        }
        lease {
          nextToken
        }
        amendments {
          nextToken
        }
        clause {
          nextToken
        }
        createdAt
        active
        updatedAt
        propertyUnitsId
        owner
      }
      createdAt
      confidence
      updatedAt
      unitClauseId
      owner
    }
  }
`;
