import classNames from 'classnames';
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import _ from 'lodash';

import styles from "./styles.module.scss";

export const SearchBar = ({className, darkmode, onSubmit = _.noop, ...props}) => {
  const checkOnKeyPress = event => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }
  return (
    <div className={classNames(styles.searchContainer, className)}>
      <SearchIcon style={{ color: darkmode === "true" ? "white" : "#196FE4" }}/>
      <Input
          placeholder={props.placeholder}
          disableUnderline
          onKeyPress={checkOnKeyPress}
          {...props}
          sx={{
            color: darkmode === "true" ? "#C4C4C4" : "#747474",
            marginLeft: 1,
            flex: 1,
          }}
        />
    </div>
  )
}