import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { DropResult } from 'react-beautiful-dnd';
import DraggableList from './DraggableList.jsx';
import DraggableListItem from './DraggableListItem.jsx';
import pick from '@cahil/utils/accessors/pick';
import { reorder } from './helpers.js';
import './LeaseCard.css';
import 'scrollable-component';

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 0,
    height: 70,
    width: 500
  },
  root: {
    display: 'flow-root',
    flexWrap: 'wrap',
    // width: 500
  }
});

const LeaseCard = (props) => {
  const [leaseFilePath, setLeaseFilePath] = React.useState("");
  const getItems = (count: number, fp) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
      id: `Item ${k + 1}`,
      primary: `Lease File:`,
      secondary: fp
    }
  ));
  const [items, setItems] = React.useState(getItems(1, leaseFilePath));

  console.log(leaseFilePath, items);
  const lease = props.lease.items;
  const leaseExtract = {lease};
  React.useEffect(() => {
    try{
      console.log("Lease in lease card: ", leaseExtract.lease[0]);
      setLeaseFilePath(leaseFilePath + leaseExtract.lease[0].id)
      setItems(getItems(1, leaseFilePath + leaseExtract.lease[0].id));
    }catch(err){
      console.log("no lease yet in lease card")
    }
  },[lease]);

  // console.log("Lease in lease card: ", leaseExtract.lease[0]);

  const classes = useStyles();


  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);
    console.log(newItems)

    setItems(newItems);
  };

  return (
    <div className={classes.root} >
        <scrollable-component>
            <Paper className={classes.flexPaper } >
                <DraggableList items={items} onDragEnd={onDragEnd} />
            </Paper>
        </scrollable-component>
    </div>
  );
};

export default LeaseCard;
