import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Box, Container, Stack, Typography, IconButton, Button, Grid} from "@mui/material";
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import API, {graphqlOperation} from "@aws-amplify/api";
import {Auth} from "aws-amplify";
import orderBy from "lodash/orderBy";

import {updateProperty} from "graphql/mutations";
import {getProperty} from "../../graphql/queries";
import {onCreateUnit} from "../../graphql/subscriptions";
import NavLayout from "../../components/NavLayout";
import TenantCard from "../../components/TenantCard";
import EditTenant from "components/EditTenant";

const Header = ({property, searchValue, onChangeSearch, onHandleBack}) => {
  return (
    <Box
      height="260px"
      padding={{xs: "20px 10px", sm: "40px"}}
      sx={{
        background: "#232839",
        boxShadow: "0px 10px 10px rgba(155, 155, 155, 0.25);",
        borderRadius: "0px 0px 8px 8px;"
      }}>
      <Container maxWidth="lg">
        <Stack direction="column" spacing={5}>
          <Stack direction={{xs: "column", sm: "row"}} alignItems={{xs: "flex-start", sm: "center"}} justifyContent="space-between">
            <Stack direction="row" alignItems="center" onClick={onHandleBack} sx={{cursor: 'pointer'}}>
              <IconButton>
                <img src="/img/arrow-left-icon@2x.svg" alt="arrow-left" />
              </IconButton>
              <Typography sx={{fontSize: "14px", fontWeight: 400, color: "white"}}>
                Back to all Properties
              </Typography>
            </Stack>
            <Stack 
              width={{xs: "100%", sm: "335px"}}
              height="45px"
              padding="12px"
              direction="row"
              alignItems="center"
              sx={{background: "rgba(255, 255, 255, 0.1);", border: "1px solid #DFE2E8;", borderRadius: "8px"}}>
              <SearchIcon style={{ color: "white" }}/>
              <Input
                placeholder="Search property"
                disableUnderline
                value={searchValue}
                onChange={onChangeSearch}
                sx={{
                  color: "#C4C4C4",
                  marginLeft: 1,
                  flex: 1,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack alignItems="center" sx={{position: "relative"}}>
              <img src={`/img/icon-home.png`} width="80px" height="80px" alt="icon-home" />
              <Typography sx={{position: "absolute", top:"-20px", fontSize: "14px", fontWeight: 400, color: "white"}}>
                {property?.active === false && 'Inactive'}
              </Typography>
            </Stack>
            {property?.category && 
            (<Stack direction="column">
              <Typography sx={{fontSize: "14px", fontWeight: 400, color: "white"}}>
                {property?.category}  •   {property?.totalSqFt?.toLocaleString()} SF
              </Typography>
              <Typography sx={{fontSize: "27px", fontWeight: 700, color: "white"}}>
                {property?.name}
              </Typography>
              <Typography sx={{fontSize: "14px", fontWeight: 400, color: "white"}}>
                {property?.name}   |    {property?.street}
              </Typography>
            </Stack>)}
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const Tenant = () => {
  const history = useHistory();
  const {propertyId} = useParams();

  const [loading, setLoading] = useState(false);
  const [editTenantVisible, setEditTenantVisible] = useState(false);
  const [editingTenant, setEditingTenant] = useState();
  const [property, setProperty] = useState({});
  const [propertyCategory, setPropertyCategory] = useState(0);
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [order, setOrder] = useState('asc');

  const sortList = [
    {
      id: 0,
      title: 'Alphabetical',
      value: 'name',
    },
    {
      id: 1,
      title: 'Last Updated',
      value: 'updatedAt',
    },
    {
      id: 2,
      title: 'Square Footage',
      value: 'squareFeet',
    },
    {
      id: 3,
      title: 'Expiration Date',
      value: 'updatedAt',
    },
  ];

  useEffect(() => {
    refreshUnits(propertyId);
    Auth.currentAuthenticatedUser().then(user => {
      API.graphql(
        graphqlOperation(onCreateUnit, { owner: user.username })
      ).subscribe(unit => {
        const newUnit = unit.value.data.onCreateUnit;
        setProperty(alb => {
          return { ...alb, units: { items: [newUnit, ...alb.units.items] } };
        });
      });
      });
  }, [propertyId]);

  useEffect(() => {
    if (property.units) {
      setPropertyUnits(property.units.items);
    }
  }, [property]);

  useEffect(() => {
    if (keyword === '') {
      setFilteredUnits(propertyUnits);
    } else {
      const filteredList = propertyUnits.filter(item => item.name?.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredUnits(filteredList);
    }
  }, [keyword, propertyUnits]);

  const refreshUnits = (propertyId) => {
    setLoading(true);
    API.graphql(graphqlOperation(getProperty, { id: propertyId })).then(propertyDetails => {
      setLoading(false);
      setProperty(propertyDetails.data.getProperty);
      console.log(propertyDetails);
    });
  };

  const _updateProperty = (propertyId) => {
    const input = {
      id: propertyId,
    };
    API.graphql(graphqlOperation(updateProperty, { input }));
  };

  const handleCategoryChange = (index) => {
    setPropertyCategory(index);
    if (propertyCategory === index) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrder('asc');
    }
  };

  const handleChangeKeyword = event => {
    setKeyword(event.target.value);
  };

  const onTenantUpdated = () => {
    setEditTenantVisible(false);
    refreshUnits(propertyId);
    _updateProperty(propertyId);
  };

  const onEditTenant = useCallback(unit => {
    setEditingTenant(unit);
    setEditTenantVisible(true);
  }, []);

  const handleBack = () => {
    history.goBack();
  }

  return (    
    <NavLayout>
      <Header property={property} searchValue={keyword} onChangeSearch={handleChangeKeyword} onHandleBack={handleBack} />
      <Box padding={{xs: "20px 10px", md: "30px"}}>
        <Container maxWidth="lg">
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{fontSize: "27px", fontWeight: 700, color: "black"}}>
                Tenants ({filteredUnits.length})
              </Typography>
              <Button 
                variant="contained"
                onClick={() => history.push(`/createtenant/${propertyId}`)}
                sx={{fontSize: "14px", fontWeight: 500, textTransform: "none", borderRadius: "8px"}}>
                Add New Tenant
              </Button>
            </Stack>
            <Box>
              <Typography sx={{fontSize: "12px"}}>Sort by:</Typography>
              <Stack marginTop="8px" spacing={{xs: 1, sm: 2}} direction="row">
                {
                  sortList.map(sortItem => (
                    <Button
                      key={sortItem.id}
                      size="small"
                      variant={propertyCategory === sortItem.id ? "contained" : "outlined"}
                      sx={{textTransform: "none", borderRadius: {xs: "12px", sm: "32px"}, fontSize: {xs: "11px", sm: "14px"}}}
                      onClick={() => handleCategoryChange(sortItem.id)}>
                      {sortItem.title}
                    </Button>
                  ))
                }
              </Stack>
              <Grid container spacing={2} marginTop="0px">
                {orderBy(filteredUnits, [sortList[propertyCategory].value], [order]).map(unit => (
                  <Grid item key={unit.id}>
                    <TenantCard
                      retail={unit.name}
                      squareFeet={unit.squareFeet}
                      updatedAt={unit.updatedAt}
                      creationDate={unit.createdAt}
                      property={property}
                      unit={unit}
                      onEditTenant={onEditTenant}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Container>
        {editTenantVisible && (
          <EditTenant
            setOpenPopup={setEditTenantVisible}
            onTenantUpdated={onTenantUpdated}
            unit={editingTenant}
          />
        )}
      </Box>
    </NavLayout>
  )
};

export default Tenant;
