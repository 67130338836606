
import React, {useState} from "react";
import DropZone from "../../../../components/DropZone";
import FileUploadItem from "../../../../components/FileUploadItem";
import styles from "./styles.module.scss";

const StepSecond = ({file, progress, errorLeaseFile, handleSelectFile, handleRemove}) => {
  return (
    <div className={styles.container}>
      <h3>Upload Main Lease</h3>
      <p className={styles.description}>Drag and drop or search to upload the tenant’s lease</p>
      <div className={styles.inputWrapper}>
        <DropZone fileTypes={["pdf"]} multiple={false} handleChange={handleSelectFile}>
          <div className={styles.dropzone}>
            <div className={styles.items}>
              <img src="/img/ic-document.svg" alt="document" />
              <p className={styles.dropzoneTitle}>Select the PDF files to upload</p>
              <p className={styles.dropzoneDescription}>Or drag and drop them here.</p>
              <div className={styles.selectButton}>
                Select Documents
              </div>
            </div>
          </div>
        </DropZone>
        {errorLeaseFile && <p className={styles.errorLabel}>Please upload document first</p>}
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>Uploaded Document</p>
        <div className={styles.fileItems}>
          {file &&
          <FileUploadItem
            progress={progress}
            fileIndex={0}
            fileName={file.name}
            fileSize={file.size}
            handleRemove={handleRemove}
          />}
        </div>
      </div>
    </div>
  )
};

export default StepSecond;
