import {Box} from "@mui/material";

const TabPanel = ({children, maxHeight, value, index}) => {

  return (
    <Box
      width="100%"
      maxHeight={maxHeight}
      paddingTop={2}
      overflow="auto"
      hidden={value !== index}>
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
