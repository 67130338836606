import React, {useState} from "react";
import {Stack, Box, Typography, Button, IconButton} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import API, { graphqlOperation } from "@aws-amplify/api";
import {updateProperty} from "graphql/mutations";

import InputWithBorder from "components/InputWithBorder";
import styles from "./styles.module.scss";
import {STATES} from "data/state";
import {getNumber} from "../../utils/number-util";

const EditProperty = ({
  setOpenPopup,
  property,
  onPropertyUpdated,
}) => {
  const [loading, setLoading] = useState(false);
  const [propertyCategory, setPropertyCategory] = useState(property.category);
  const [propertyName, setPropertyName] = useState(property.name);
  const [propertySqft, setPropertySqft] = useState(property.totalSqFt.toLocaleString());
  const [propertyStreet, setPropertyStreet] = useState(property.street);
  const [propertyCity, setPropertyCity] = useState(property.city);
  const [propertyState, setPropertyState] = useState(property.state);
  const [propertyZipcode, setPropertyZipcode] = useState(property.zipcode);

  const [errorPropertyName, setErrorPropertyName] = useState(false);
  const [errorPropertySqft, setErrorPropertySqft] = useState(false);
  const [errorPropertyStreet, setErrorPropertyStreet] = useState(false);
  const [errorPropertyCity, setErrorPropertyCity] = useState(false);
  const [errorPropertyZipCode, setErrorPropertyZipCode] = useState(false);


  const handleCategoryChange = type => setPropertyCategory(type);

  const handleNameChange = event => {
    setErrorPropertyName(false);
    setPropertyName(event.target.value);
  };

  const handleSqftChange = event => {
    setErrorPropertySqft(false);
    const number = getNumber(event.target.value);
    if (number === 0) {
      setPropertySqft('');
    } else {
      setPropertySqft(number.toLocaleString());
    }
  };

  const handleStreetChange = event => {
    setErrorPropertyStreet(false);
    setPropertyStreet(event.target.value);
  };

  const handleCityChange = event => {
    setErrorPropertyCity(false);
    setPropertyCity(event.target.value);
  };

  const handleStateChange = event => {
    setPropertyState(event.target.value);
  };

  const handleZipcodeChange = event => {
    setErrorPropertyZipCode(false);
    setPropertyZipcode(event.target.value);
  };

  const handleUpdateProperty = () => {
    if (checkValidation()) {
      _updateProperty();
    }
  };

  const _updateProperty = async () => {
    const input = {
      id: property.id,
      name: propertyName,
      category: propertyCategory,
      street: propertyStreet,
      city: propertyCity,
      state: propertyState,
      zipcode: propertyZipcode,
      totalSqFt: getNumber(propertySqft),
    };
    setLoading(true);
    const result = await API.graphql(graphqlOperation(updateProperty, { input }));
    setLoading(false);
    console.log(result);
    onPropertyUpdated();
  };
  
  const checkValidation = () => {
    if (propertyName.trim() === "") {
      setErrorPropertyName(true);
      return false;
    }
    if (propertySqft.trim() === "") {
      setErrorPropertySqft(true);
      return false;
    }
    if (propertyStreet.trim() === "") {
      setErrorPropertyStreet(true);
      return false;
    }
    if (propertyCity.trim() === "") {
      setErrorPropertyCity(true);
      return false;
    }
    if (propertyZipcode.trim() === "") {
      setErrorPropertyZipCode(true);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.popupContainer}>
      <div className={styles.container}>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{fontSize: "27px", fontWeight: 700, color: "black"}}>
            Edit Property
          </Typography>
          <IconButton
            sx={{color: "#2666E6"}}
            onClick={() => setOpenPopup(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography sx={{fontSize: "16px", color: "black"}}>
          Please fill out all the fields to edit property.
        </Typography>
        <Box marginTop="30px" marginBottom="36px" width="100%" height="1px" backgroundColor="#C4C4C4" />
        <Typography sx={{fontSize: "12px", color: "black"}}>
          SELECT A PROPERTY TYPE
        </Typography>
        <Stack direction="row" spacing={{xs: 1, md: 0}} justifyContent="space-between" marginTop="14px">
          <Button
            disableElevation={true}
            variant={propertyCategory === "MDU" ? "contained" : "outlined"} 
            onClick={() => handleCategoryChange("MDU")}
            sx={{width: "130px", borderRadius: "20px", textTransform: "none"}}>
            MDU
          </Button>
          <Button
            disableElevation={true}
            variant={propertyCategory === "Office" ? "contained" : "outlined"} 
            onClick={() => handleCategoryChange("Office")}
            sx={{width: "130px", borderRadius: "20px", textTransform: "none"}}>
            Office
          </Button>
          <Button
            disableElevation={true}
            variant={propertyCategory === "Retail" ? "contained" : "outlined"} 
            onClick={() => handleCategoryChange("Retail")}
            sx={{width: "130px", borderRadius: "20px", textTransform: "none"}}>
            Retail
          </Button>
          <Button
            disableElevation={true}
            variant={propertyCategory === "Industrial" ? "contained" : "outlined"} 
            onClick={() => handleCategoryChange("Industrial")}
            sx={{width: "130px", borderRadius: "20px", textTransform: "none"}}>
            Industrial
          </Button>
        </Stack>
        <div className={styles.inputWrapper}>
          <p className={styles.label}>NAME</p>
          <InputWithBorder
            className={styles.input}
            placeholder="Property Name"
            value={propertyName}
            error={errorPropertyName}
            onChange={handleNameChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <p className={styles.label}>Total Property Size (in Square Feet)</p>
          <InputWithBorder
            className={styles.input}
            placeholder="100,000 Sq Ft"
            value={propertySqft}
            error={errorPropertySqft}
            onChange={handleSqftChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <p className={styles.label}>Address</p>
          <InputWithBorder
            className={styles.input}
            placeholder="Address"
            value={propertyStreet}
            error={errorPropertyStreet}
            onChange={handleStreetChange}
          />
        </div>
        <Stack direction={{xs: "column", md: "row"}} justifyContent="space-between">
          <div className={classNames(styles.inputWrapper, styles.cityWrapper)}>
            <p className={styles.label}>City</p>
            <InputWithBorder
              className={styles.input}
              placeholder="City"
              value={propertyCity}
              error={errorPropertyCity}
              onChange={handleCityChange}
            />
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>State</p>
            <select className={styles.select} value={propertyState} onChange={handleStateChange}>
            {
              STATES.map((state, index) => 
                <option key={index} value={state.value}>{state.name}</option>
              )
            }
            </select>
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>Zip</p>
            <InputWithBorder
              className={styles.input}
              placeholder="12345"
              type="number"
              value={propertyZipcode}
              error={errorPropertyZipCode}
              onChange={handleZipcodeChange}
            />
          </div>
        </Stack>
        <Stack marginTop="40px" direction="row" justifyContent="center">
          <Stack spacing={1}>
            <LoadingButton
              loading={loading}
              onClick={handleUpdateProperty}
              variant="contained"
              sx={{borderRadius: "8px", textTransform: "none"}}>
              Update Property
            </LoadingButton>
            {/* <Button
              onClick={handleCreateAndAddLater}
              sx={{textTransform: "none"}}>
              Create and Add Tenant Later
            </Button> */}
          </Stack>
        </Stack>
      </div>
    </div>
  )
};

export default EditProperty;
