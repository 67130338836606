import React, {useState} from 'react';
import {Stack} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import InputWithBorder from "components/InputWithBorder";
import styles from './styles.module.scss';

const SignUp = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errorUserName, setErrorUserName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const handleChangeUserName = (event) => {
    const value = event.target.value;
    setUserName(value);
    if (value !== '') {
      setErrorUserName(false);
    }
  };

  const handleChangePassword = (event) => {
    const value = event.target.value;
    setPassword(value);
    if (value !== '') {
      setErrorPassword(false);
    }
  };

  const handleChangeConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    if (value !== '') {
      setErrorConfirmPassword(false);
    }
  };

  const handleChangeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    if (value !== '') {
      setErrorEmail(false);
    }
  };

  const handleSignIn = () => {

  };

  return (
    <Stack direction="column">
      <h3 className={styles.title}>Create a New Account</h3>
      <p className={styles.subtitle}>Please fill out all the fields to create a new account.</p>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>USERNAME</p>
        <InputWithBorder
          className={styles.input}
          placeholder="johnsmith1"
          value={username}
          error={errorUserName}
          onChange={handleChangeUserName}
        />
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>PASSWORD</p>
        <InputWithBorder
          className={styles.input}
          placeholder="Password"
          type="password"
          value={password}
          error={errorPassword}
          onChange={handleChangePassword}
        />
        <InputWithBorder
          className={styles.input}
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          error={errorConfirmPassword}
          errorMessage={'Password not matched'}
          onChange={handleChangeConfirmPassword}
        />
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>EMAIL</p>
        <InputWithBorder
          className={styles.input}
          placeholder="johnsmith@email.com"
          type="email"
          value={email}
          error={errorEmail}
          errorMessage={'Invalid email'}
          onChange={handleChangeEmail}
        />
      </div>
      <LoadingButton
        sx={{marginTop: "30px", backgroundColor: "#2666E6"}}
        variant="contained"
        onClick={handleSignIn}>
        Create Account
      </LoadingButton>
    </Stack>
  );
};

export default SignUp;
