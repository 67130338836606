import React, {useEffect, useState, useRef} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Container, Stack, Typography, IconButton, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import API, { graphqlOperation } from "@aws-amplify/api";
import {Storage} from "aws-amplify";

import awsconfig from "../../aws-exports";
import { createUnit, createAmendment, createLease, updateUnit, updateProperty} from "../../graphql/mutations";
import { getProperty } from "../../graphql/queries";
import NavLayout from "../../components/NavLayout";
import StepProgress from "./components/StepProgress";
import {StepFirst, StepSecond, StepThird} from "./components/Steps";
import { reorder } from "../../components/LeaseCard/helpers";
import {getNumber} from "../../utils/number-util";

const CreateTenant = () => {
  const {propertyId} = useParams();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState({});
  const [newUnit, setNewUnit] = useState(null);
  const [errorTenantName, setErrorTenantName] = useState(false);
  const [errorSquareFeet, setErrorSquareFeet] = useState(false);
  const [errorLeaseFile, setErrorLeaseFile] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [squareFeet, setSquareFeet] = useState('');
  const [leaseFile, setLeaseFile] = useState(null);
  const [leaseFileUploadProgress, setLeaseFileuploadProgress] = useState(0);
  const [amendmentFiles, setAmendmentFiles] = useState([]);

  useEffect(() => {
    API.graphql(graphqlOperation(getProperty, { id: propertyId })).then(propertyDetails => {
      setProperty(propertyDetails.data.getProperty);
    });
  }, []);

  const createTenantAndUploadLease = async () => {
    const input = {
      squareFeet: getNumber(squareFeet),
      name: tenantName,
      createdAt: `${Date.now()}`,
      propertyUnitsId: propertyId,
    }
    setLoading(true);
    const result = await API.graphql(graphqlOperation(createUnit, { input }));
    setLoading(false);
    setNewUnit(result);
    console.log('######## New Unit ##########');
    console.log(result);
    uploadNewLease(result);
  };

  const progressCallback = (progress) => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100
    );
    setLeaseFileuploadProgress(progressInPercentage);
  };

  const uploadNewLease = async (newLease) => {
    // Upload lease
    setLoading(true);
    const leaseKey = `PDF Uploads/${property.name}/${tenantName}/Lease_${leaseFile.name}`;
    const typeLength = leaseFile.type.substring(leaseFile.type.indexOf('/')).length;
    let lName = `textract/${property.name}/${tenantName}/`;
    const textractName = leaseFile.name.substring(0, leaseFile.name.length - 4);
    await Storage.put(leaseKey, leaseFile, {contentType: leaseFile.type, progressCallback})
    .then (result => {
      console.log('############ Lease uploaded ##########');
      console.log(result);
    })
    .catch(err => {
      console.log(err);
      setLoading(false);
    });
    await addLease(newLease, leaseKey, lName);
    setLoading(false);
    setStep(2);
  };

  const addLease = async (newLease, leaseKey, lName) => {
    const leaseName = tenantName + "_Lease";
    // Then add Lease
    const input = {
      id: leaseKey,
      bucket: awsconfig.aws_user_files_s3_bucket,
      s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + leaseKey,
      textractBucket: lName,
      name: leaseName,
      status: "Processing", 
      createdAt: `${Date.now()}`,
      unitLeaseId: newLease?.data.createUnit.id,
    }
    console.log(input)
    const result2 = await API.graphql(graphqlOperation(createLease, { input }));
    console.log('############ Lease created ##########');
    console.log(result2);
  };

  const uploadAmendments = async (index) => {
    if (index >= amendmentFiles.length) {
      setLoading(false);
      history.goBack();
      return;
    }
    const indexToString = index.toString()
    const amendmentFile = amendmentFiles[index];
    const amendmentKey = `PDF Uploads/${property.name}/${tenantName}/Amendment_${indexToString}_${amendmentFile.file.name}`;
    const amendmentName = amendmentFile.file.name;
    const typeLength = amendmentFile.file.type.substring(amendmentFile.file.type.indexOf('/')).length;
    let aName = `textract/${property.name}/${tenantName}/`;

    await Storage.put(amendmentKey, amendmentFile.file, {contentType: amendmentFile.file.type, progressCallback(progress) {
      const progressInPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      const files = [...amendmentFiles];
      files[index].progress = progressInPercentage;
      setAmendmentFiles(files);
    }})
    .then (result => {
      console.log('############ Amendment uploaded ##########');
      console.log(result);
    })
    .catch(err => {
      console.log(err);
      setLoading(false);
    });
    const input = {
      id: amendmentKey,
      bucket: awsconfig.aws_user_files_s3_bucket,
      textractBucket: aName,
      s3Path: "s3://whitemarshappleaseuploads150041-poc/public/" + amendmentKey,
      name: amendmentName,
      status: "Processing", 
      createdAt: `${Date.now()}`,
      unitAmendmentsId: newUnit?.data.createUnit.id,
    }
    const result2 = await API.graphql(graphqlOperation(createAmendment, {input }));
    console.log('############ Amendment created ##########');
    console.log(result2);
    uploadAmendments(index+1);
  }

  const handleAmendments = () => {
    if (amendmentFiles.length !== 0) {
      setLoading(true);
      uploadAmendments(0);
    } else {
      history.goBack();
    }
  };

  const handleChangeTenantName = (event) => {
    const value = event.target.value;
    setTenantName(value);
    if (value !== '') {
      setErrorTenantName(false);
    }
  };

  const handleChangeSquareFeet = (event) => {
    const value = event.target.value;
    const number = getNumber(value);
    if (number === 0) {
      setSquareFeet('');
    } else {
      setSquareFeet(number.toLocaleString());
    }
    if (value !== '') {
      setErrorSquareFeet(false);
    }
  };

  const handleChangeLeaseFile = file => {
    setErrorLeaseFile(false);
    setLeaseFileuploadProgress(0);
    setLeaseFile(file);
    setLoading(false);
  };

  const handleRemoveLeaseFile = index => {
    setLeaseFile(null);
  }

  const handleSelectAmendmentFile = fileList => {
    const files = [...amendmentFiles];
    Array.from(fileList).forEach(file => {
      if (files.length < 10) {
        files.push({file, progress: 0, edit: false});
      }
    });
    setAmendmentFiles(files);
  }

  const handleRenameAmendmentFile = (index, newFileName) => {
    if (newFileName.includes(".")) {
      const files = [...amendmentFiles];
      const newFile = files[index];
      files[index] = {file: new File([newFile.file], newFileName), progress: 0, edit: false};
      setAmendmentFiles(files);
    }
  }

  const handleRemoveAmendmentFile = index => {
    amendmentFiles.splice(index, 1);
    const updatedFiles = [...amendmentFiles];
    setAmendmentFiles(updatedFiles);
  }

  const handleEditAmendmentFile = index => {
    const files = [...amendmentFiles];
    files[index].edit = true;
    setAmendmentFiles(files);
  }

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(amendmentFiles, source.index, destination.index);

    setAmendmentFiles(newItems);
  };

  const validateFields = () => {
    if (tenantName.trim() === '') {
      setErrorTenantName(true);
      return false;
    }
    if (squareFeet.trim() === '') {
      setErrorSquareFeet(true);
      return false;
    }
    return true;
  }

  const validateLeaseFile = () => {
    if (leaseFile === null) {
      setErrorLeaseFile(true);
      return false;
    }
    return true;
  }

  const handleContinue = () => {
    if (step === 0) {
      if (!validateFields()) {
        return;
      } else {
        setStep(1);
      }
    } else if (step === 1) {
      if (!validateLeaseFile()) {
        return;
      } else {
        createTenantAndUploadLease();
      }
    } else if (step === 2) {
      handleAmendments();
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <NavLayout>
      <Box padding={{xs: "20px 10px", md: "30px"}} background="#F8F8F8">
        <Container maxWidth="lg">
          <Stack spacing={2} sx={{position: "relative"}}>
            <Stack direction="row">
              <Stack direction="row" alignItems="center" onClick={handleBack} sx={{cursor: 'pointer'}}>
                <IconButton>
                  <img src="/img/arrow-left-icon@2x.svg" alt="arrow-left" />
                </IconButton>
                <Typography sx={{fontFamily: "Roboto", fontSize: "14px", fontWeight: 400, color: "black"}}>
                  Back to all Tenants
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{fontSize: "30px", fontWeight: 700, color: "black"}}>
                Add a New Tenant
              </Typography>
            </Stack>
            <StepProgress step={step}/>
            <Stack sx={{alignItems: "center", paddingTop: "32px"}}>
              {step === 0 && 
                <StepFirst
                  tenantName={tenantName}
                  squareFeet={squareFeet}
                  errorTenantName={errorTenantName}
                  errorSquareFeet={errorSquareFeet}
                  handleChangeTenantName={handleChangeTenantName}
                  handleChangeSquareFeet={handleChangeSquareFeet}
                />}
              {step === 1 && 
                <StepSecond
                  progress={leaseFileUploadProgress}
                  file={leaseFile}
                  errorLeaseFile={errorLeaseFile}
                  handleSelectFile={handleChangeLeaseFile}
                  handleRemove={handleRemoveLeaseFile}
                />}
              {step === 2 && 
                <StepThird
                  files={amendmentFiles}
                  handleSelectFile={handleSelectAmendmentFile}
                  handleRenameFile={handleRenameAmendmentFile}
                  handleRemove={handleRemoveAmendmentFile}
                  handleEdit={handleEditAmendmentFile}
                  onDragEnd={onDragEnd}
                />}
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{width: "256px"}}
                onClick={handleContinue}
              >
                Continue
              </LoadingButton>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </NavLayout>
  )

};

export default CreateTenant;